import { DefaultValues } from 'react-hook-form';

import { CreateEditSessionBody, MarketParams, Session } from 'common/models';
import { decimalToPercent, percentToDecimal } from 'common/utils';

import {
  MarketParamsFormData,
  CreateEditSessionFormData,
} from './validationSchema';

export type CreateEditSessionFormDefaultValues =
  DefaultValues<CreateEditSessionFormData>;

const marketParamsTransformIn = (
  value: MarketParams | null
): MarketParamsFormData | null => {
  if (!value) return null;

  const {
    filterParams,
    triggerParams,
    openPositionParams,
    closePositionParams,
  } = value;

  const { priceChangeFromPrevCloseRange, marketCapRange } = filterParams ?? {};
  const {
    lastPeriodInterval,
    lastPeriodPriceChange,
    priceChangeFromPrevClose,
  } = triggerParams ?? {};
  const { chaseDown, additionalSteps } = openPositionParams ?? {};
  const { stopLossParams, takeProfitParams, timeBasedParams } =
    closePositionParams ?? {};

  const lastPeriodIntervalNumericPart = parseInt(
    lastPeriodInterval?.replace(/\D/g, '') ?? '0'
  );
  const transformedTriggerParams = {
    ...(triggerParams ?? {}),
    lastPeriodInterval:
      lastPeriodIntervalNumericPart === 0 ? undefined : lastPeriodInterval,
    lastPeriodPriceChange:
      lastPeriodPriceChange === 0
        ? undefined
        : decimalToPercent(lastPeriodPriceChange),
    priceChangeFromPrevClose:
      priceChangeFromPrevClose === 0
        ? undefined
        : decimalToPercent(priceChangeFromPrevClose),
  };

  return {
    ...value,
    filterParams: {
      ...(filterParams ?? {}),
      marketCapRange: {
        ...(marketCapRange ?? {}),
        min: marketCapRange?.min
          ? parseFloat((marketCapRange.min / 1000000).toFixed(2))
          : undefined,
        max: marketCapRange?.max
          ? parseFloat((marketCapRange.max / 1000000).toFixed(2))
          : undefined,
      },
      priceChangeFromPrevCloseRange: {
        ...(priceChangeFromPrevCloseRange ?? {}),
        min: decimalToPercent(priceChangeFromPrevCloseRange?.min),
        max: decimalToPercent(priceChangeFromPrevCloseRange?.max),
      },
    },
    triggerParams: transformedTriggerParams,
    openPositionParams: {
      ...(openPositionParams ?? {}),
      chaseDown: decimalToPercent(chaseDown),
      additionalSteps: (additionalSteps ?? []).map((item) => ({
        ...(item ?? {}),
        triggeringPriceChange: decimalToPercent(item.triggeringPriceChange),
      })),
    },
    closePositionParams: {
      ...(closePositionParams ?? {}),
      stopLossParams: (stopLossParams ?? []).map((item) => ({
        ...(item ?? {}),
        sizeOfClosingPositionPart: decimalToPercent(
          item.sizeOfClosingPositionPart
        ),
      })),
      takeProfitParams: (takeProfitParams ?? []).map((item) => ({
        ...(item ?? {}),
        triggeringPriceChange: decimalToPercent(item.triggeringPriceChange),
        sizeOfClosingPositionPart: decimalToPercent(
          item.sizeOfClosingPositionPart
        ),
      })),
      timeBasedParams: (timeBasedParams ?? []).map((item) => ({
        ...(item ?? {}),
        sizeOfClosingPositionPart: decimalToPercent(
          item.sizeOfClosingPositionPart
        ),
      })),
    },
  } as MarketParamsFormData;
};

const marketParamsTransformOut = (
  value: MarketParamsFormData | undefined | null
): MarketParams | undefined | null => {
  if (!value) return null;

  const {
    active,
    filterParams,
    triggerParams,
    openPositionParams,
    closePositionParams,
  } = value;

  if (!active) {
    return {
      active: false,
    };
  }

  const { priceChangeFromPrevCloseRange, marketCapRange } = filterParams ?? {};
  const {
    lastPeriodInterval,
    lastPeriodPriceChange,
    priceChangeFromPrevClose,
  } = triggerParams ?? {};
  const { chaseDown, additionalSteps } = openPositionParams ?? {};
  const { stopLossParams, takeProfitParams, timeBasedParams } =
    closePositionParams ?? {};

  return {
    ...value,
    filterParams: {
      ...(filterParams ?? {}),
      marketCapRange: {
        ...(marketCapRange ?? {}),
        min: marketCapRange?.min ? marketCapRange.min * 1000000 : undefined,
        max: marketCapRange?.max ? marketCapRange.max * 1000000 : undefined,
      },
      priceChangeFromPrevCloseRange:
        priceChangeFromPrevCloseRange.min && priceChangeFromPrevCloseRange.max
          ? {
              min: percentToDecimal(priceChangeFromPrevCloseRange?.min),
              max: percentToDecimal(priceChangeFromPrevCloseRange?.max),
            }
          : priceChangeFromPrevCloseRange,
    },
    triggerParams: {
      ...(triggerParams ?? {}),
      lastPeriodInterval: lastPeriodInterval ?? '0s',
      lastPeriodPriceChange: percentToDecimal(lastPeriodPriceChange) ?? 0,
      priceChangeFromPrevClose: percentToDecimal(priceChangeFromPrevClose) ?? 0,
    },
    openPositionParams: {
      ...(openPositionParams ?? {}),
      chaseDown: percentToDecimal(chaseDown),
      additionalSteps: additionalSteps
        ? additionalSteps.map((item) => ({
            ...(item ?? {}),
            triggeringPriceChange: percentToDecimal(item.triggeringPriceChange),
          }))
        : additionalSteps,
    },
    closePositionParams: {
      ...(closePositionParams ?? {}),
      stopLossParams: stopLossParams
        ? stopLossParams.map((item) => ({
            ...(item ?? {}),
            sizeOfClosingPositionPart: percentToDecimal(
              item.sizeOfClosingPositionPart
            ),
          }))
        : stopLossParams,
      takeProfitParams: takeProfitParams
        ? takeProfitParams.map((item) => ({
            ...(item ?? {}),
            triggeringPriceChange: percentToDecimal(item.triggeringPriceChange),
            sizeOfClosingPositionPart: percentToDecimal(
              item.sizeOfClosingPositionPart
            ),
          }))
        : takeProfitParams,
      timeBasedParams: timeBasedParams
        ? timeBasedParams.map((item) => ({
            ...(item ?? {}),
            sizeOfClosingPositionPart: percentToDecimal(
              item.sizeOfClosingPositionPart
            ),
          }))
        : timeBasedParams,
    },
  } as MarketParams;
};

export const transformIn = (
  values: Session | null | undefined
): CreateEditSessionFormDefaultValues | undefined => {
  if (!values) return undefined;

  const { sessionName, rthParams, phParams, ahParams, blacklist } = values;

  return {
    sessionName: sessionName ?? '',
    rthParams: marketParamsTransformIn(rthParams),
    phParams: marketParamsTransformIn(phParams),
    ahParams: marketParamsTransformIn(ahParams),
    blacklist: blacklist ?? [],
  } as CreateEditSessionFormDefaultValues;
};

export const transformOut = (
  values: CreateEditSessionFormDefaultValues
): CreateEditSessionBody => {
  const { rthParams, phParams, ahParams } = values;

  return {
    ...values,
    rthParams: marketParamsTransformOut(
      rthParams as MarketParamsFormData | undefined
    ),
    phParams: marketParamsTransformOut(
      phParams as MarketParamsFormData | undefined
    ),
    ahParams: marketParamsTransformOut(
      ahParams as MarketParamsFormData | undefined
    ),
  } as CreateEditSessionBody;
};
