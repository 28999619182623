import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import {
  Tokens,
  SendOtpCodeBody,
  VerifyOtpCodeBody,
  ResendOtpCodeBody,
} from 'common/models';
import { authRepository } from 'common/repositories';
import http from 'core/services/api';
import storage from 'core/services/storage';

type State = Tokens;

type Actions = {
  logout: () => void;
  refresh: () => Promise<string | null>;
  setTokenToHeadersFromStore: () => void;
  sendOtpCode: (body: SendOtpCodeBody) => Promise<boolean>;
  resendOtpCode: (body: ResendOtpCodeBody) => Promise<boolean>;
  verifyOtpCode: (body: VerifyOtpCodeBody) => Promise<boolean>;
};

export type AuthStore = State & Actions;

const defaultState: State = {
  accessToken: null,
  refreshToken: null,
};

const useAuthStore = create<AuthStore>()(
  persist(
    (set, get) => ({
      ...defaultState,

      setTokenToHeadersFromStore: () => {
        const { accessToken } = get();

        if (accessToken) {
          http.setAuthTokenToHeaders(accessToken);
        }
      },

      sendOtpCode: async (body) => {
        const res = await authRepository.sendOtpCode(body);
        const data = res.data;

        return !!data;
      },

      resendOtpCode: async (body) => {
        const res = await authRepository.resendOtpCode(body);
        const data = res.data;

        return !!data;
      },

      verifyOtpCode: async (body) => {
        const res = await authRepository.verifyOtpCode(body);
        const data = res.data;

        if (data === null) {
          return false;
        }

        const { accessToken, refreshToken } = data;

        if (accessToken !== null) {
          set(() => ({
            accessToken,
            refreshToken,
          }));
          http.setAuthTokenToHeaders(accessToken);
        }

        return true;
      },

      refresh: async () => {
        const { refreshToken, logout } = get();

        if (!refreshToken) {
          logout();
          return null;
        }

        const res = await authRepository.refreshTokens({ refreshToken });
        const data = res.data;

        if (data === null) {
          logout();
          return null;
        }

        const { accessToken } = data;

        if (accessToken !== null) {
          set(() => ({ accessToken }));
          http.setAuthTokenToHeaders(accessToken ?? '');
          return accessToken;
        } else {
          logout();
          return null;
        }
      },

      logout: () => {
        set(() => defaultState);
        http.clearAuthTokenFromHeaders();
      },
    }),
    {
      name: storage.AUTH_STORE_KEY,
    }
  )
);

export default useAuthStore;
