import { useFetch } from 'common/hooks';
import { tasksRepository } from 'common/repositories';

export type OrdersFetchArgs = Parameters<typeof tasksRepository.getTasks>[0];

export const useFetchTasksWithTimer = (args: OrdersFetchArgs) => {
  const { data, ...hook } = useFetch(tasksRepository.getTasks, {
    instantFetch: true,
    args,
  });

  return { data: data?.data, ...hook };
};

export type CancelTaskArgs = Parameters<typeof tasksRepository.cancelTask>[0];

export const useCancelTask = (args: CancelTaskArgs) => {
  const { data, ...hook } = useFetch(tasksRepository.cancelTask, {
    instantFetch: false,
    args,
  });

  return { data: data?.data, ...hook };
};
