import { clsx } from 'clsx';
import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  withConfirmActionDialog,
  WithConfirmActionDialogSharedProps,
} from 'features/components';

import StopSessionDialog from './StopSessionDialog';
import useSessionDetailsStore from '../editSessionStore';

export type StartStopButtonProps = {
  disabled: boolean;
};

const StartStopButton: FC<
  WithConfirmActionDialogSharedProps & StartStopButtonProps
> = ({ disabled, showConfirmActionDialog }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const isStarted = useSessionDetailsStore(
    (state) => state.session?.status === 'active'
  );

  const handleClose = () => setIsOpen(false);

  const handleOnClick = async () => {
    const store = useSessionDetailsStore.getState();

    if (isStarted) {
      setIsOpen(true);
    } else {
      await showConfirmActionDialog({
        onConfirm: store.startSession,
        title: t('components.confirmActionDialog.toDoSomethingWithSession', {
          action: t('components.confirmActionDialog.actions.start'),
        }),
      });
    }
  };

  return (
    <Fragment>
      <button
        onClick={handleOnClick}
        disabled={disabled}
        className={clsx(isStarted ? 'bg-yellow-500' : 'bg-green-500')}
      >
        {t(isStarted ? 'common.stop' : 'common.start')}
      </button>
      <StopSessionDialog
        isOpen={isOpen}
        onClose={handleClose}
        showConfirmActionDialog={showConfirmActionDialog}
      />
    </Fragment>
  );
};

const StartStopButtonWithHoc = withConfirmActionDialog(StartStopButton);

export default StartStopButtonWithHoc;
