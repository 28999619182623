import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Session } from 'common/models';
import { formatDateWithTimezone } from 'common/utils';
import { PATHS } from 'core/router';
import { SessionStatuses } from 'features/components';

export type SessionItemProps = {
  data: Session;
};

const SessionItem: FC<SessionItemProps> = ({ data }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { sessionId, status, sessionName, monitorStatus, createdAt } = data;

  const goToEditSession = () => {
    if (sessionId !== null) {
      navigate(PATHS.getEditSession(sessionId));
    }
  };

  const goToSessionDetails = () => {
    if (sessionId !== null) {
      navigate(PATHS.getSessionDetails(sessionId));
    }
  };

  return (
    <div className="flex flex-col justify-between gap-4 rounded-lg border-2 border-slate-300 p-4 md:flex-row md:items-center">
      <div className="flex flex-col gap-1">
        <h3 className="break-words">{sessionName}</h3>
        {createdAt && (
          <p className="mb-2 break-words text-sm">
            {formatDateWithTimezone(createdAt)}
          </p>
        )}
        <SessionStatuses status={status} monitorStatus={monitorStatus} />
      </div>
      <div className="flex flex-col gap-3">
        <button onClick={goToEditSession} className="outlined-button-s">
          {t('pages.sessions.editSession')}
        </button>
        <button onClick={goToSessionDetails} className="outlined-button-s">
          {t('pages.sessions.sessionDetails')}
        </button>
      </div>
    </div>
  );
};

export default SessionItem;
