import { Duration, Nullable, Time } from 'common/types';
import { MinMaxValue } from 'core/components/form';

import { OrderPriceType, OrderType } from './order';
import { Paginated, Sorted } from './paginated';

export const EXCHANGE = {
  NASDAQ: 'XNAS',
  NYSE: 'XNYS',
} as const;
export type Exchange = (typeof EXCHANGE)[keyof typeof EXCHANGE];

export type FilterParams = {
  priceChangeFromPrevCloseRange?: MinMaxValue;
  currentDayVolumeRange?: MinMaxValue;
  lastPeriodVolumeRange?: MinMaxValue;
  lastPeriod?: Duration;
  marketCapRange?: MinMaxValue;
  priceRange?: MinMaxValue;
  exchanges: Exchange[];
};

export type TriggerParams = {
  lastPeriodInterval: Duration;
  lastPeriodPriceChange: number;
  priceChangeFromPrevClose: number;
};

export type StopLossParams = {
  maxPositionVolume: number;
  sizeOfClosingPositionPart: number;
};

export type TakeProfitParams = {
  triggeringPriceChange: number;
  sizeOfClosingPositionPart: number;
};

export type TimeBasedClosePositionParams = {
  triggeringTime: Time;
  sizeOfClosingPositionPart: number;
  orderType: OrderType;
  orderPriceType: OrderPriceType;
  entriesCount: number;
  openEntryInterval: Duration;
  orderRefreshInterval: Duration;
  openEntryLimit: Duration;
  exitDelay: Duration;
};

export type ClosePositionParams = Nullable<{
  stopLossParams: StopLossParams[];
  takeProfitParams: TakeProfitParams[];
  timeBasedParams: TimeBasedClosePositionParams[];
}>;

export type AdditionalStepItem = {
  triggeringPriceChange: number;
  entrySize: number;
};

export type OpenPositionParams = {
  maxPositionVolume: number;
  maxEntryVolume: number;
  openEntryInterval: Duration;
  orderType: OrderType;
  orderPriceType: OrderPriceType;
  orderRefreshInterval: Duration;
  openEntryLimit: Duration;
  chaseDown: number;
  additionalSteps: AdditionalStepItem[];
  maxTickersPositionsPerDay: number;
};

export type MarketParams = Nullable<{
  active?: boolean;
  marketHoursRange?: MinMaxValue<string>;
  filterParams?: FilterParams;
  triggerParams?: TriggerParams;
  openPositionParams?: OpenPositionParams;
  closePositionParams?: ClosePositionParams;
}>;

export type CreateEditSessionBody = {
  sessionName?: string;
  rthParams?: MarketParams;
  phParams?: MarketParams;
  ahParams?: MarketParams;
  blacklist?: string[];
};

export const MARKET_STATUS = {
  PH_MARKET: 'phMarket',
  AH_MARKET: 'ahMarket',
  RTH_MARKET: 'rthMarket',
  CLOSED: 'closed',
} as const;
export type MarketStatus = (typeof MARKET_STATUS)[keyof typeof MARKET_STATUS];

export const SESSION_STATUS = {
  ERROR: 'error',
  ACTIVE: 'active',
  STOPPED: 'stopped',
} as const;
export type SessionStatus =
  (typeof SESSION_STATUS)[keyof typeof SESSION_STATUS];

export const SESSION_SEARCH_MONITOR_STATUS = {
  ERROR: 'error',
  PAUSED: 'paused',
  STOPPED: 'stopped',
  PH_MARKET: 'phMarket',
  AH_MARKET: 'ahMarket',
  RTH_MARKET: 'rthMarket',
} as const;
export type SessionSearchStatus =
  (typeof SESSION_SEARCH_MONITOR_STATUS)[keyof typeof SESSION_SEARCH_MONITOR_STATUS];
export type SessionMonitorStatus =
  (typeof SESSION_SEARCH_MONITOR_STATUS)[keyof typeof SESSION_SEARCH_MONITOR_STATUS];

export type Session = Nullable<{
  sessionId: string;
  createdAt: Date;
  sessionName: string;
  strategyName: string;
  rthParams: MarketParams;
  phParams: MarketParams;
  ahParams: MarketParams;
  blacklist: string[];
  whitelist: string[];
  status: SessionStatus;
  marketStatus: MarketStatus;
  searchStatus: SessionSearchStatus;
  monitorStatus: SessionMonitorStatus;
}>;

export type EditSessionBody = {
  sessionId: string;
  body: CreateEditSessionBody;
};

export const ORDER_BY_SESSION_FILTER = {
  CREATEDAT: 'createdAt',
  STATUS: 'status',
  SEARCH_STATUS: 'searchStatus',
  MONITOR_STATUS: 'monitorStatus',
  SESSION_NAME: 'sessionName',
  STRATEGY_NAME: 'strategyName',
} as const;
export type OrderBySessionFilter =
  (typeof ORDER_BY_SESSION_FILTER)[keyof typeof ORDER_BY_SESSION_FILTER];

export type SessionFilters = Paginated &
  Sorted<OrderBySessionFilter> &
  Partial<{
    status: SessionStatus;
  }>;

export const STOP_BEHAVIOR = {
  NONE: 'none',
  CANCEL_EXECUTING: 'cancel_executing',
} as const;
export type StopBehavior = (typeof STOP_BEHAVIOR)[keyof typeof STOP_BEHAVIOR];

export type StopSessionBody = {
  sessionId: string;
  stopBehavior?: StopBehavior;
};
