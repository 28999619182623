import React, { FC } from 'react';

import ClosingParameters from './ClosePositionInputs';
import FilterInputs from './FilterInputs';
import OpenPositionInputs from './OpenPositionInputs';
import TriggerInputs from './TriggerInputs';
import { CommonInputsProps } from './types';

const CommonInputs: FC<CommonInputsProps> = (props) => {
  return (
    <div className="flex flex-col gap-6">
      <FilterInputs {...props} />
      <hr className="ml-[28px] border-t-2 border-green-500" />
      <TriggerInputs {...props} />
      <hr className="ml-[28px] border-t-2 border-green-500" />
      <OpenPositionInputs {...props} />
      <hr className="ml-[28px] border-t-2 border-green-500" />
      <ClosingParameters {...props} />
    </div>
  );
};

export default CommonInputs;
