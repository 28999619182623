import { Label } from '@headlessui/react';
import React, { FC, Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ORDER_TYPE } from 'common/models';
import {
  formatBigNumber,
  formatNumber,
  unformatBigNumber,
  unformatNumber,
} from 'common/utils';
import { DurationInput, TextInput } from 'core/components/form';
import { Tooltip } from 'core/components/uikit';
import { DisclosureWithTitle } from 'features/components';

import AdditionalPositionsInputs from './AdditionalPositionsInputs';
import OrderTypeInputs from './OrderTypeInputs';
import { CommonInputsProps } from './types';

const OpenPositionInputs: FC<CommonInputsProps> = ({
  control,
  basename,
  disabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <DisclosureWithTitle
      title={
        <h4>{t('pages.createSession.common.form.openPositionParams.title')}</h4>
      }
      defaultOpen={true}
    >
      <TextInput
        control={control}
        name={`${basename}.openPositionParams.maxPositionVolume`}
        componentProps={{
          disabled,
          label: t(
            'pages.createSession.common.form.openPositionParams.maxPositionVolume.label'
          ),
          suffix: '$',
          format: formatBigNumber,
          unformat: unformatBigNumber,
        }}
      />
      <TextInput
        control={control}
        name={`${basename}.openPositionParams.maxEntryVolume`}
        componentProps={{
          disabled,
          label: t(
            'pages.createSession.common.form.openPositionParams.maxEntryVolume.label'
          ),
          suffix: '$',
          format: formatBigNumber,
          unformat: unformatBigNumber,
        }}
      />
      <DurationInput
        control={control}
        name={`${basename}.openPositionParams.openEntryInterval`}
        componentProps={{
          disabled,
          label: t(
            'pages.createSession.common.form.openPositionParams.openEntryInterval.label'
          ),
        }}
      />
      <TextInput
        control={control}
        name={`${basename}.openPositionParams.maxTickersPositionsPerDay`}
        defaultValue={3}
        componentProps={{
          disabled,
          label: (
            <Label className="caption mb-4 ml-1 flex flex-row items-center gap-1 text-xs">
              {t(
                'pages.createSession.common.form.openPositionParams.maxTickersPositionsPerDay.label'
              )}
              <Tooltip
                text={t(
                  'pages.createSession.common.form.openPositionParams.maxTickersPositionsPerDay.helperText'
                )}
                position="top"
              />
            </Label>
          ),
        }}
      />
      <OpenPositionLimitInputs {...{ control, basename, disabled }} />
      <AdditionalPositionsInputs {...{ control, basename, disabled }} />
    </DisclosureWithTitle>
  );
};

export const OpenPositionLimitInputs: FC<CommonInputsProps> = ({
  control,
  basename,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const { watch, resetField, setValue } = useFormContext();
  const orderType = watch(`${basename}.openPositionParams.orderType`);
  const isLimit = orderType === ORDER_TYPE.LIMIT;

  const handleOrderTypeChange = (newOrderType: string) => {
    resetField(`${basename}.openPositionParams.orderRefreshInterval`);
    resetField(`${basename}.openPositionParams.openEntryLimit`);
    resetField(`${basename}.openPositionParams.chaseDown`);

    const isNewLimit = newOrderType === ORDER_TYPE.LIMIT;
    if (isNewLimit) {
      setValue(`${basename}.openPositionParams.orderRefreshInterval`, '');
      setValue(`${basename}.openPositionParams.openEntryLimit`, '');
      setValue(`${basename}.openPositionParams.chaseDown`, '');
    } else {
      setValue(`${basename}.openPositionParams.orderRefreshInterval`, '0s');
      setValue(`${basename}.openPositionParams.openEntryLimit`, '0s');
      setValue(`${basename}.openPositionParams.chaseDown`, '0');
    }
  };

  return (
    <Fragment>
      <OrderTypeInputs
        {...{
          control,
          disabled,
          orderTypeName: `${basename}.openPositionParams.orderType`,
          orderPriceTypeName: `${basename}.openPositionParams.orderPriceType`,
          marketOrderPriceTypeLabel: t(
            'pages.createSession.common.form.openPositionParams.orderPriceType.market'
          ),
          askOrderPriceTypeLabel: t(
            'pages.createSession.common.form.openPositionParams.orderPriceType.askLimit'
          ),
          bidOrderPriceTypeLabel: t(
            'pages.createSession.common.form.openPositionParams.orderPriceType.bidLimit'
          ),
          onOrderTypeChange: handleOrderTypeChange,
        }}
      />
      {isLimit && (
        <Fragment>
          <DurationInput
            control={control}
            name={`${basename}.openPositionParams.orderRefreshInterval`}
            componentProps={{
              disabled,
              label: t(
                'pages.createSession.common.form.openPositionParams.orderRefreshInterval.label'
              ),
            }}
          />
          <DurationInput
            control={control}
            name={`${basename}.openPositionParams.openEntryLimit`}
            componentProps={{
              disabled,
              label: t(
                'pages.createSession.common.form.openPositionParams.openEntryLimit.label'
              ),
            }}
          />
          <TextInput
            control={control}
            name={`${basename}.openPositionParams.chaseDown`}
            componentProps={{
              disabled,
              label: t(
                'pages.createSession.common.form.openPositionParams.chaseDown.label'
              ),
              suffix: '%',
              format: formatNumber,
              unformat: unformatNumber,
            }}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default OpenPositionInputs;
