import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { FC } from 'react';
import { useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { formatNumber, unformatNumber } from 'common/utils';
import { TextInput } from 'core/components/form';

import { CommonInputsProps } from './types';

const TakeProfitInputs: FC<CommonInputsProps> = ({
  control,
  basename,
  disabled = false,
}) => {
  const { t } = useTranslation();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `${basename}.closePositionParams.takeProfitParams`,
  });

  return (
    <div>
      {fields.map((field, index) => (
        <div key={field.id} className="mb-4">
          <div className="mb-4 flex flex-row items-center gap-2">
            <button
              onClick={() => remove(index)}
              disabled={disabled}
              className="h-full bg-red-500 p-2"
            >
              <XMarkIcon className="size-5" />
            </button>
            <p className="text-sm">
              {t(
                'pages.createSession.closePositionParams.form.takeProfitParams.title',
                { number: index + 1 }
              )}
            </p>
          </div>

          <div className="flex w-full gap-3">
            <TextInput
              control={control}
              name={`${basename}.closePositionParams.takeProfitParams.${index}.triggeringPriceChange`}
              componentProps={{
                disabled,
                label: t(
                  'pages.createSession.closePositionParams.form.takeProfitParams.triggeringPriceChange.label'
                ),
                suffix: '%',
                format: formatNumber,
                unformat: unformatNumber,
              }}
            />
            <TextInput
              name={`${basename}.closePositionParams.takeProfitParams.${index}.sizeOfClosingPositionPart`}
              control={control}
              componentProps={{
                disabled,
                label: t(
                  'pages.createSession.closePositionParams.form.takeProfitParams.sizeOfClosingPositionPart.label'
                ),
                suffix: '%',
                format: formatNumber,
                unformat: unformatNumber,
              }}
            />
          </div>
        </div>
      ))}
      {fields.length < 5 && (
        <button
          type="button"
          onClick={() =>
            append({
              triggeringPriceChange: undefined,
              sizeOfClosingPositionPart: undefined,
            })
          }
          className="outlined-button-s mt-2 w-full"
          disabled={disabled}
        >
          {t(
            'pages.createSession.closePositionParams.form.takeProfitParams.add'
          )}
        </button>
      )}
    </div>
  );
};

export default TakeProfitInputs;
