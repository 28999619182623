import { t } from 'i18next';
import React, { ChangeEventHandler } from 'react';
import { FieldValues, useController } from 'react-hook-form';
import * as yup from 'yup';

import { DefaultFormInputProps } from './types';
import { TextInput, TextInputProps } from '../uikit';

export type MinMaxValue<T = number> = { min: T; max: T };

export function createMinMaxInputSchema({
  min,
  max,
  required,
}: {
  min: number;
  max: number;
  required: boolean;
}) {
  return yup.object({
    min: yup
      .number()
      .transform((value, originalValue) =>
        typeof originalValue === 'string' && originalValue.trim() === ''
          ? null
          : value
      )
      .when([], {
        is: () => required,
        then: (schema) =>
          schema
            .required(t('errors.validation.isRequired', { name: 'Min' }))
            .typeError(
              t('errors.validation.mustBe', { name: 'Min', type: 'number' })
            )
            .max(
              yup.ref('max'),
              t('errors.validation.cantBeGreater', {
                name: 'Min',
                length: 'Max',
              })
            )
            .min(
              min,
              t('errors.validation.minLength', { name: 'Min', length: min })
            ),
        otherwise: (schema) =>
          schema
            .nullable()
            .notRequired()
            .max(
              yup.ref('max'),
              t('errors.validation.cantBeGreater', {
                name: 'Min',
                length: 'Max',
              })
            )
            .min(
              min,
              t('errors.validation.minLength', { name: 'Min', length: min })
            ),
      }),

    max: yup
      .number()
      .transform((value, originalValue) =>
        typeof originalValue === 'string' && originalValue.trim() === ''
          ? null
          : value
      )
      .when([], {
        is: () => required,
        then: (schema) =>
          schema
            .required(t('errors.validation.isRequired', { name: 'Max' }))
            .typeError(
              t('errors.validation.mustBe', { name: 'Max', type: 'number' })
            )
            .min(
              yup.ref('min'),
              t('errors.validation.cantBeLess', {
                name: 'Max',
                length: 'Min',
              })
            )
            .max(
              max,
              t('errors.validation.maxLength', { name: 'Max', length: max })
            ),
        otherwise: (schema) =>
          schema
            .nullable()
            .notRequired()
            .min(
              yup.ref('min'),
              t('errors.validation.cantBeLess', {
                name: 'Max',
                length: 'Min',
              })
            )
            .max(
              max,
              t('errors.validation.maxLength', { name: 'Max', length: max })
            ),
      }),
  });
}

type MinMaxInputProps<FormValues extends FieldValues> = {
  min: DefaultFormInputProps<FormValues, TextInputProps>;
  max: DefaultFormInputProps<FormValues, TextInputProps>;
};

const MinMaxInput = <FormValues extends FieldValues>({
  min: { componentProps: minComponentProps, ...minInput },
  max: { componentProps: maxComponentProps, ...maxInput },
}: MinMaxInputProps<FormValues>) => {
  const { field: minField, fieldState: minFieldState } =
    useController(minInput);
  const { field: maxField, fieldState: maxFieldState } =
    useController(maxInput);

  const minError = minFieldState.error?.message;
  const maxError = maxFieldState.error?.message;

  const minOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (minComponentProps && minComponentProps.onChange) {
      minComponentProps.onChange(e);
    }

    minField.onChange(e);
    maxField.onChange(maxField.value);
  };

  const maxOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (maxComponentProps && maxComponentProps.onChange) {
      maxComponentProps.onChange(e);
    }

    maxField.onChange(e);
    minField.onChange(minField.value);
  };

  return (
    <div className="flex flex-row gap-3">
      <TextInput
        {...minField}
        error={minError}
        {...minComponentProps}
        onChange={minOnChange}
      />
      <TextInput
        {...maxField}
        error={maxError}
        {...maxComponentProps}
        onChange={maxOnChange}
      />
    </div>
  );
};

export default MinMaxInput;
