import { Nullable } from 'common/types';

import { Paginated, Sorted } from './paginated';

export const ORDER_STATUS = {
  EXECUTING: 'executing',
  EXECUTED: 'executed',
  REJECTED: 'rejected',
  CANCELED: 'canceled',
  CANCELING: 'canceling',
  PLACED: 'placed',
} as const;
export type OrderStatus = (typeof ORDER_STATUS)[keyof typeof ORDER_STATUS];

export const ORDER_SIDE = {
  BUY: 'buy',
  SELL: 'sell',
  SHORT: 'short',
} as const;
export type OrderSide = (typeof ORDER_SIDE)[keyof typeof ORDER_SIDE];

export const ORDER_TYPE = {
  MARKET: 'market',
  LIMIT: 'limit',
  STOP_MARKET: 'stopMarket',
} as const;
export type OrderType = (typeof ORDER_TYPE)[keyof typeof ORDER_TYPE];

export const ORDER_PRICE_TYPE = {
  MARKET: 'market',
  ASK: 'ask',
  BID: 'bid',
} as const;
export type OrderPriceType =
  (typeof ORDER_PRICE_TYPE)[keyof typeof ORDER_PRICE_TYPE];

export const ORDER_CLOSE_TRIGGER_TYPE = {
  TAKE_PROFIT: 'takeProfit',
  TIME_BASED: 'timeBased',
  STOP_LOSS: 'stopLoss',
} as const;
export type OrderCloseTriggerType =
  (typeof ORDER_CLOSE_TRIGGER_TYPE)[keyof typeof ORDER_CLOSE_TRIGGER_TYPE];

export type Order = Nullable<{
  id: string;
  ticker: string;
  externalId: string;
  status: OrderStatus;
  side: OrderSide;
  type: OrderType;
  priceType: OrderPriceType;
  entryPrice: number;
  executedPrice: number;
  quantity: number;
  executedQuantity: number;
  sentDate: Date;
  filledDate: Date;
  updatedDate: Date;
  positionId: string;
  closeTriggerType: OrderCloseTriggerType;
  sizeOfClosingPositionPart: number;
  token: number;
}>;

export const ORDER_BY_ORDER_FILTER = {
  SIDE: 'side',
  STATUS: 'status',
  TYPE: 'type',
  ENTRYPRICE: 'entryPrice',
  EXECUTEDPRICE: 'executedPrice',
  QUANTITY: 'quantity',
  SENTDATE: 'sentDate',
  FILLEDDATE: 'filledDate',
} as const;
export type OrderByOrderFilter =
  (typeof ORDER_BY_ORDER_FILTER)[keyof typeof ORDER_BY_ORDER_FILTER];

export type OrderFilter = Paginated &
  Sorted<OrderByOrderFilter> &
  Partial<{
    status: OrderStatus;
    positionId: string;
    side: OrderSide;
  }>;
