import { t } from 'i18next';
import * as yup from 'yup';

import { createNumberInputSchema } from 'core/components/form';

export const tradeSettingsFormSchema = yup
  .object({
    maxNewPositionsPerDay: createNumberInputSchema({
      min: 1,
      required: true,
      max: Infinity,
      name: t(
        'pages.settings.tradeSettingsForm.fields.maxNewPositionsPerDay.name'
      ),
    }),
    maxTotalPositions: createNumberInputSchema({
      min: 1,
      required: true,
      max: Infinity,
      name: t('pages.settings.tradeSettingsForm.fields.maxTotalPositions.name'),
    }),
    doNotTradeOverLimit: createNumberInputSchema({
      min: 1,
      required: true,
      max: Infinity,
      name: t(
        'pages.settings.tradeSettingsForm.fields.doNotTradeOverLimit.name'
      ),
    }),
  })
  .required();

export type TradeSettingsFormData = yup.InferType<
  typeof tradeSettingsFormSchema
>;
