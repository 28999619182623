import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PATHS } from 'core/router';
import {
  withConfirmActionDialog,
  WithConfirmActionDialogSharedProps,
} from 'features/components';

import useSessionDetailsStore from '../editSessionStore';

export type DeleteButtonProps = {
  disabled: boolean;
};

const DeleteButton: FC<
  WithConfirmActionDialogSharedProps & DeleteButtonProps
> = ({ disabled, showConfirmActionDialog }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onDelete = async () => {
    const store = useSessionDetailsStore.getState();

    const success = await store.deleteSession();

    if (success) {
      navigate(PATHS.sessions);
    }
  };

  const handleOnClick = () =>
    showConfirmActionDialog({
      onConfirm: onDelete,
      title: t('components.confirmActionDialog.toDoSomethingWithSession', {
        action: t('components.confirmActionDialog.actions.delete'),
      }),
    });

  return (
    <button onClick={handleOnClick} disabled={disabled} className="bg-red-500">
      {t('common.delete')}
    </button>
  );
};

const DeleteButtonWithHoc = withConfirmActionDialog(DeleteButton);

export default DeleteButtonWithHoc;
