import { Field, Label, Input } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/16/solid';
import { clsx } from 'clsx';
import React, {
  useState,
  KeyboardEvent,
  ChangeEvent,
  useEffect,
  InputHTMLAttributes,
} from 'react';

import { InputProps } from './types';

export type TagInputProps = InputProps &
  Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
    onChange?: (tags: string[]) => void;
    value?: string[];
  };

const TagInput: React.FC<TagInputProps> = ({
  value,
  onChange,
  label,
  error,
  placeholder,
  className,
  disabled,
  ...inputProps
}) => {
  const [tags, setTags] = useState<string[]>(value || []);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (value) {
      setTags(value);
    }
  }, [value]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === ',' || e.key === ' ') {
      e.preventDefault();
      if (inputValue.trim()) {
        const newTags = inputValue
          .split(/[\s,]+/)
          .filter(Boolean)
          .map((tag) => tag.trim());

        const uniqueTags = Array.from(new Set([...tags, ...newTags]));
        setTags(uniqueTags);
        setInputValue('');
        onChange?.(uniqueTags);
      }
    } else if (e.key === 'Backspace' && inputValue === '') {
      const updatedTags = tags.slice(0, -1);
      setTags(updatedTags);
      onChange?.(updatedTags);
    }
  };

  const handleRemoveTag = (index: number) => {
    const updatedTags = tags.filter((_, i) => i !== index);
    setTags(updatedTags);
    onChange?.(updatedTags);
  };

  return (
    <Field
      className={clsx('flex w-full flex-col', className)}
      disabled={disabled}
    >
      {label &&
        (typeof label === 'string' ? (
          <Label className="caption mb-1 ml-1 text-xs">{label}</Label>
        ) : (
          label
        ))}
      <div
        className={clsx(
          'w-full rounded-md border-[1px] border-slate-400 bg-slate-100 px-2 py-2 text-base',
          {
            'bg-slate-200': disabled,
          }
        )}
      >
        <div className="flex flex-wrap items-center gap-2">
          {tags.map((tag, index) => (
            <div
              key={index}
              className="flex items-center gap-2 rounded bg-white px-2"
            >
              <p className="caption font-light text-black">{tag}</p>
              <button
                className="h-0 p-0"
                onClick={() => handleRemoveTag(index)}
                disabled={disabled}
              >
                <XMarkIcon className="size-4 fill-black" />
              </button>
            </div>
          ))}
          <Input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
            className="flex-grow bg-transparent p-1 focus:outline-none"
            placeholder={placeholder}
            disabled={disabled}
            {...inputProps}
          />
        </div>
      </div>
      {error && (
        <p className="caption ml-1 mt-1 text-sm text-appRose">{error}</p>
      )}
    </Field>
  );
};

export default TagInput;
