export const decimalToPercent = (
  value: number | null | undefined,
  decimals: number = 1
): number | undefined | null =>
  value !== null && value !== undefined
    ? Math.round(value * 100 * 10 ** decimals) / 10 ** decimals
    : undefined;

export const percentToDecimal = (
  value: number | null | undefined
): number | undefined | null =>
  value !== null && value !== undefined ? value / 100 : undefined;
