import { Dialog, DialogBackdrop } from '@headlessui/react';
import React, { FC, useState } from 'react';

import { StopBehavior } from 'common/models';
import { Callback } from 'common/types';
import { WithConfirmActionDialogSharedProps } from 'features/components';

import ChooseStopBehaviorContent from './ChooseStopBehaviorDialogContent';
import StopSessionContent from './StopSessionDialogContent';

export type StopSessionDialogProps = {
  isOpen: boolean;
  onClose: Callback;
  showConfirmActionDialog: WithConfirmActionDialogSharedProps['showConfirmActionDialog'];
};

const StopSessionDialog: FC<StopSessionDialogProps> = ({
  isOpen,
  onClose,
  showConfirmActionDialog,
}) => {
  const [stopBehavior, setStopBehavior] = useState<StopBehavior>();

  const handleClose = () => {
    setStopBehavior(undefined);
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} className="relative z-50">
      <DialogBackdrop className="fixed inset-0 bg-black/30" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        {stopBehavior === undefined ? (
          <ChooseStopBehaviorContent
            {...{ onClose: handleClose, setStopBehavior }}
          />
        ) : (
          <StopSessionContent
            {...{
              stopBehavior,
              setStopBehavior,
              onClose: handleClose,
              showConfirmActionDialog,
            }}
          />
        )}
      </div>
    </Dialog>
  );
};

export default StopSessionDialog;
