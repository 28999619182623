import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextInput } from 'core/components/form';
import { CircularIndicator } from 'core/components/uikit';
import { DisclosureWithTitle } from 'features/components';

import {
  TradeSettingsFormData,
  tradeSettingsFormSchema,
} from './validationSchema';

const defaultValues = {
  maxNewPositionsPerDay: 50,
  maxTotalPositions: 75,
  doNotTradeOverLimit: 3,
};

const TradeSettingsForm = () => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<TradeSettingsFormData>({
    resolver: yupResolver(tradeSettingsFormSchema),
    mode: 'all',
    defaultValues,
  });

  const isButtonDisabled = !isValid || isSubmitting;

  const onSubmit = handleSubmit((values) => {
    console.log(values);
  });

  return (
    <DisclosureWithTitle
      title={<h4>{t('pages.settings.tradeSettingsForm.title')}</h4>}
      defaultOpen
    >
      <form
        className="flex max-w-full flex-col gap-4 sm:max-w-80"
        onSubmit={onSubmit}
      >
        <TextInput
          control={control}
          name="maxNewPositionsPerDay"
          componentProps={{
            type: 'number',
            label: t(
              'pages.settings.tradeSettingsForm.fields.maxNewPositionsPerDay.label'
            ),
          }}
        />
        <TextInput
          control={control}
          name="maxTotalPositions"
          componentProps={{
            type: 'number',
            label: t(
              'pages.settings.tradeSettingsForm.fields.maxTotalPositions.label'
            ),
          }}
        />
        <TextInput
          control={control}
          name="doNotTradeOverLimit"
          componentProps={{
            type: 'number',
            label: t(
              'pages.settings.tradeSettingsForm.fields.doNotTradeOverLimit.label'
            ),
          }}
        />
        <button type="submit" disabled={isButtonDisabled}>
          {isSubmitting ? <CircularIndicator /> : t('common.save')}
        </button>
      </form>
    </DisclosureWithTitle>
  );
};

export default TradeSettingsForm;
