import { t } from 'i18next';
import React from 'react';
import {
  useController,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form';
import * as yup from 'yup';

import { RadioGroup, RadioGroupProps } from '../uikit';

export const createRadioGroupInputSchema = ({
  name,
  required,
}: {
  name: string;
  required: boolean;
}) =>
  yup.string().when([], {
    is: () => required,
    then: (schema) =>
      schema.required(t('errors.validation.isRequired', { name })),
    otherwise: (schema) => schema.notRequired(),
  });

export type RadioGroupInputProps<
  ValueT extends string,
  FormValues extends FieldValues,
> = UseControllerProps<FormValues> & {
  componentProps: RadioGroupProps<ValueT>;
};

const RadioGroupInput = <
  ValueT extends string,
  FormValues extends FieldValues,
>({
  componentProps,
  ...input
}: RadioGroupInputProps<ValueT, FormValues>) => {
  const { field, fieldState } = useController(input);

  const error = fieldState.error?.message;
  const { value, onChange } = field;

  const handleChange = (newValue: ValueT) => {
    onChange(newValue);
    componentProps.onChange?.(newValue);
  };

  return (
    <RadioGroup
      {...componentProps}
      error={error}
      value={value}
      onChange={handleChange}
    />
  );
};

export default RadioGroupInput;
