import { TaskFilter, PaginatedResult, Task } from 'common/models';
import http from 'core/services/api';

class TasksRepository {
  getTasks = async ({
    page = 1,
    perPage = 10,
    orderDir = 'asc',
    orderBy = 'triggeringTime',
    ...params
  }: TaskFilter) =>
    http.fetch(async (axios, signal) => {
      return axios.get<PaginatedResult<Task>>(`/tasks/filter`, {
        signal,
        params: {
          ...params,
          orderDir,
          orderBy,
          perPage,
          page,
        },
      });
    });

  cancelTask = async ({ id }: { id: string }) =>
    http.fetch(async (axios, signal) => {
      return axios.post<void>(
        `/tasks/${id}/cancel`,
        {},
        {
          signal,
        }
      );
    });
}

export const tasksRepository = new TasksRepository();
