import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Ticker, ListType, MarketType, TickersFilter } from 'common/models';
import { FiltersProps } from 'core/components/uikit';

import ListTypeSelectInput from './ListTypeSelectInput';
import MarketTypeSelectInput from './MarketTypeSelectInput';
import { useFetchSessionTickers } from './useFetchSessionTickers';
import SearchInput from '../SearchInput';

export type TickersFiltersFiltersProps = FiltersProps<Ticker> &
  Pick<ReturnType<typeof useFetchSessionTickers>, 'args' | 'fetch'>;

const TickersFilters: FC<TickersFiltersFiltersProps> = ({
  setPagination,
  setExpanded,
  fetch,
  args,
}) => {
  const { t } = useTranslation();
  const onChangeFiltration = useCallback(
    (newFilters: TickersFilter) => {
      fetch({
        ...args,
        ...newFilters,
        page: 1,
      });
      setPagination((old) => ({ ...old, pageIndex: 0 }));
      setExpanded({});
    },
    [args, fetch, setPagination]
  );

  const onSearchByTicker = useCallback<(ticker: string) => void>(
    (ticker) => onChangeFiltration({ ticker }),
    [onChangeFiltration]
  );

  const onFilterByMarketType = useCallback<
    (marketType: MarketType | undefined) => void
  >((marketType) => onChangeFiltration({ marketType }), [onChangeFiltration]);

  const onFilterByListType = useCallback<
    (listType: ListType | undefined) => void
  >((listType) => onChangeFiltration({ listType }), [onChangeFiltration]);

  return (
    <div className="flex flex-row gap-2">
      <SearchInput
        value={args?.ticker}
        onSearch={onSearchByTicker}
        placeholder={t('components.tickersTable.filters.ticker')}
      />
      <MarketTypeSelectInput
        type={args?.marketType}
        onChange={onFilterByMarketType}
      />
      <ListTypeSelectInput
        type={args?.listType}
        onChange={onFilterByListType}
      />
    </div>
  );
};

export default TickersFilters;
