import { t } from 'i18next';
import * as yup from 'yup';

import { createPasswordInputSchema, phoneSchema } from 'core/components/form';

export const loginSchema = yup
  .object({
    phone: phoneSchema,
    password: createPasswordInputSchema({
      name: t('pages.phone.fields.password'),
    }),
  })
  .required();

export type LoginFormData = yup.InferType<typeof loginSchema>;
