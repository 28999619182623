import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { AppSettings } from 'common/models';
import storage from 'core/services/storage';

type State = {
  appSettings: AppSettings;
};

type Actions = {
  setAppSettings: (value: Partial<AppSettings>) => void;
};

export type SettingsStore = State & Actions;

const defaultState: State = {
  appSettings: {
    showSnackbarErrorMessages: true,
  },
};

const useSettingsStore = create<SettingsStore>()(
  persist(
    immer((set) => ({
      ...defaultState,

      setAppSettings: (value) =>
        set((state) => {
          state.appSettings = {
            ...state.appSettings,
            ...value,
          };
        }),
    })),
    {
      name: storage.APP_SETTINGS_STORE_KEY,
      partialize: (state) => state.appSettings,
      merge: (persistedState, currentState) => ({
        ...currentState,
        appSettings: persistedState as AppSettings,
      }),
    }
  )
);

export default useSettingsStore;
