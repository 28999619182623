import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ListType } from 'common/models';
import { Option } from 'common/types';
import { Select } from 'core/components/uikit';

export type ListTypeFilterOption = Option<ListType | null>;

export type ListTypeSelectInputProps = {
  type: string | undefined;
  onChange: (value: ListType | undefined) => void;
};

const ListTypeSelectInput: FC<ListTypeSelectInputProps> = ({
  type,
  onChange,
}) => {
  const { t } = useTranslation();

  const filterOptions = useMemo<ListTypeFilterOption[]>(
    () => [
      {
        value: null,
        label: t('components.tickersTable.filters.listType.all'),
      },
      {
        value: 'watchlist',
        label: t('components.tickersTable.filters.listType.watchlist'),
      },
      {
        value: 'preWatchlist',
        label: t('components.tickersTable.filters.listType.preWatchlist'),
      },
    ],
    [t]
  );

  const value =
    filterOptions.find((item) => item.value === type) ?? filterOptions[0];

  return (
    <Select
      value={value}
      className="max-w-52"
      label={t('components.tickersTable.filters.listType.label')}
      onChange={(newValue) =>
        onChange((newValue as ListTypeFilterOption).value ?? undefined)
      }
      options={filterOptions}
    />
  );
};

export default ListTypeSelectInput;
