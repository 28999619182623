import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { UseControllerProps, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ORDER_PRICE_TYPE, ORDER_TYPE, OrderPriceType } from 'common/models';
import { Option } from 'common/types';
import { RadioGroupInput } from 'core/components/form';

import { CreateEditSessionFormData } from '../../validationSchema';

export type OrderTypeInputsProps = Pick<
  UseControllerProps<CreateEditSessionFormData>,
  'control'
> & {
  disabled?: boolean;
  askOrderPriceTypeLabel: string;
  bidOrderPriceTypeLabel: string;
  marketOrderPriceTypeLabel: string;
  onOrderTypeChange?: (newOrderType: string) => void;
  orderTypeName: UseControllerProps<CreateEditSessionFormData>['name'];
  orderPriceTypeName: UseControllerProps<CreateEditSessionFormData>['name'];
};

const OrderTypeInputs: FC<OrderTypeInputsProps> = ({
  marketOrderPriceTypeLabel,
  askOrderPriceTypeLabel,
  bidOrderPriceTypeLabel,
  orderPriceTypeName,
  onOrderTypeChange,
  orderTypeName,
  disabled,
  control,
}) => {
  const { t } = useTranslation();

  const { watch, resetField, setValue } = useFormContext();
  const orderPriceTypeOptionsMap = useMemo(
    () => ({
      [ORDER_PRICE_TYPE.MARKET]: {
        label: marketOrderPriceTypeLabel,
        value: ORDER_PRICE_TYPE.MARKET,
      },
      [ORDER_PRICE_TYPE.ASK]: {
        label: askOrderPriceTypeLabel,
        value: ORDER_PRICE_TYPE.ASK,
      },
      [ORDER_PRICE_TYPE.BID]: {
        label: bidOrderPriceTypeLabel,
        value: ORDER_PRICE_TYPE.BID,
      },
    }),
    [t]
  );
  const [orderPriceTypeOptions, setOrderPriceTypeOptions] = useState<
    Option<OrderPriceType>[]
  >([
    orderPriceTypeOptionsMap['market'],
    orderPriceTypeOptionsMap['bid'],
    orderPriceTypeOptionsMap['ask'],
  ]);

  const orderType = watch(orderTypeName);
  const orderPriceType = watch(orderPriceTypeName);

  useEffect(() => {
    if (orderType === ORDER_TYPE.MARKET) {
      setOrderPriceTypeOptions([orderPriceTypeOptionsMap['market']]);
      resetField(orderPriceTypeName);
      setValue(orderPriceTypeName, ORDER_PRICE_TYPE.MARKET);
    } else if (orderType === ORDER_TYPE.LIMIT) {
      const value =
        orderPriceType === orderPriceTypeOptionsMap['market'].value
          ? null
          : orderPriceType;
      setOrderPriceTypeOptions([
        orderPriceTypeOptionsMap['bid'],
        orderPriceTypeOptionsMap['ask'],
      ]);
      resetField(orderPriceTypeName);
      setValue(orderPriceTypeName, value);
    }
  }, [orderType, setValue, orderPriceTypeName, setOrderPriceTypeOptions]);

  const orderTypeOptions = useMemo(
    () => [
      {
        label: t(
          'pages.createSession.common.form.openPositionParams.orderType.market'
        ),
        value: ORDER_TYPE.MARKET,
      },
      {
        label: t(
          'pages.createSession.common.form.openPositionParams.orderType.limit'
        ),
        value: ORDER_TYPE.LIMIT,
      },
    ],
    [t]
  );

  return (
    <Fragment>
      <RadioGroupInput
        control={control}
        name={orderTypeName}
        componentProps={{
          disabled,
          label: t(
            'pages.createSession.common.form.openPositionParams.orderType.label'
          ),
          options: orderTypeOptions,
          onChange: onOrderTypeChange,
        }}
      />
      <RadioGroupInput
        control={control}
        name={orderPriceTypeName}
        componentProps={{
          disabled: disabled || !orderType,
          label: t(
            'pages.createSession.common.form.openPositionParams.orderPriceType.label'
          ),
          options: orderPriceTypeOptions,
        }}
      />
    </Fragment>
  );
};

export default OrderTypeInputs;
