import React from 'react';
import { useTranslation } from 'react-i18next';

import useSettingsStore from 'common/stores/settingsStore';
import { Switch } from 'core/components/uikit';
import { DisclosureWithTitle } from 'features/components';

const AppSettings = () => {
  const { t } = useTranslation();
  const appSettings = useSettingsStore((state) => state.appSettings);

  return (
    <DisclosureWithTitle
      title={<h4>{t('pages.settings.appSettings.title')}</h4>}
      defaultOpen
    >
      <div className="flex max-w-full flex-col gap-4 sm:max-w-80">
        <Switch
          defaultChecked={appSettings?.showSnackbarErrorMessages ?? true}
          label={t(
            'pages.settings.appSettings.fields.showSnackbarErrorMessages.label'
          )}
          onChange={(value) =>
            useSettingsStore
              .getState()
              .setAppSettings({ showSnackbarErrorMessages: value })
          }
        />
      </div>
    </DisclosureWithTitle>
  );
};

export default AppSettings;
