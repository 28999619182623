import {
  Switch as ElSwitch,
  SwitchProps as ElSwitchProps,
  Field,
  Label,
} from '@headlessui/react';
import { clsx } from 'clsx';
import React, { FC } from 'react';

import { InputProps } from './types';

export type SwitchProps = InputProps & ElSwitchProps;

const Switch: FC<SwitchProps> = ({
  className,
  disabled,
  error,
  label,
  ...otherProps
}) => {
  return (
    <Field className="flex flex-col" disabled={disabled}>
      <div className="flex flex-row items-center gap-2">
        {label &&
          (typeof label === 'string' ? (
            <Label className="caption ml-1 text-xs">{label}</Label>
          ) : (
            label
          ))}
        <ElSwitch
          {...otherProps}
          disabled={disabled}
          className={clsx(
            'group relative flex h-7 w-14 min-w-[56px] cursor-pointer rounded-full p-1 transition-colors duration-200 ease-in-out focus:outline-none',
            disabled
              ? 'cursor-not-allowed bg-red-600/40 data-[checked]:bg-green-600/40'
              : 'bg-red-600 data-[checked]:bg-green-600',
            className
          )}
        >
          <span
            aria-hidden="true"
            className="pointer-events-none inline-block size-5 -translate-x-[14px] rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-[14px]"
          />
        </ElSwitch>
      </div>
      {error && (
        <p className="caption ml-1 mt-1 text-sm text-appRose">{error}</p>
      )}
    </Field>
  );
};

export default Switch;
