import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { EXCHANGE, Session } from 'common/models';
import { useSessionsStore } from 'common/stores';
import { Wrapper } from 'core/components/common';
import { PATHS } from 'core/router';
import {
  withConfirmActionDialog,
  WithConfirmActionDialogSharedProps,
} from 'features/components';

import CreateEditSessionForm, {
  CreateEditSessionFormProps,
  transformIn,
} from '../CreateEditSessionForm';

const defaultValues: CreateEditSessionFormProps['defaultValues'] = {
  phParams: {
    active: true,
    marketHoursRange: { min: '4:00 AM', max: '9:30 AM' },
    filterParams: {
      exchanges: [EXCHANGE.NASDAQ, EXCHANGE.NYSE],
    },
  },
  rthParams: {
    active: false,
    marketHoursRange: { min: '9:30 AM', max: '4:00 PM' },
    filterParams: {
      exchanges: [EXCHANGE.NASDAQ, EXCHANGE.NYSE],
    },
  },
  ahParams: {
    active: false,
    marketHoursRange: { min: '4:00 PM', max: '8:00 PM' },
    filterParams: {
      exchanges: [EXCHANGE.NASDAQ, EXCHANGE.NYSE],
    },
  },
  whitelist: [],
  blacklist: [],
};

const CreateSession: FC<WithConfirmActionDialogSharedProps> = ({
  showConfirmActionDialog,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const sessionData: Session | null | undefined = location.state?.sessionData;
  const sessionTransformedData = transformIn(sessionData);

  const onSubmit: CreateEditSessionFormProps['onSubmit'] = async (values) =>
    showConfirmActionDialog({
      onConfirm: async () => {
        const newSession = await useSessionsStore
          .getState()
          .createSession(values);

        if (newSession !== null) {
          navigate(PATHS.sessions);
        }
      },
      title: t('components.confirmActionDialog.toDoSomethingWithSession', {
        action: t('components.confirmActionDialog.actions.create'),
      }),
    });

  return (
    <Wrapper internalClassName="flex flex-col">
      <h2 className="mb-6">{t('pages.createSession.title')}</h2>
      <CreateEditSessionForm
        onSubmit={onSubmit}
        actionButtonText={t('pages.createSession.action')}
        defaultValues={{ ...defaultValues, ...sessionTransformedData }}
      />
    </Wrapper>
  );
};

const CreateSessionWithHoc = withConfirmActionDialog(CreateSession);

export default CreateSessionWithHoc;
