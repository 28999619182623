import React from 'react';
import { useParams } from 'react-router-dom';

import { Wrapper } from 'core/components/common';
import {
  LastActionsTable,
  PositionTable,
  TickersTable,
} from 'features/components';

import { SessionParams } from './components';

const SessionDetails = () => {
  const { sessionId } = useParams();

  return (
    <Wrapper internalClassName="flex flex-col gap-8">
      <SessionParams sessionId={sessionId ?? '0'} />
      <LastActionsTable filters={{ sessionId }} />
      <TickersTable filters={{ sessionId }} />
      <PositionTable filters={{ sessionId }} />
    </Wrapper>
  );
};

export default SessionDetails;
