import { RadioGroup as EpRadioGroup, Label } from '@headlessui/react';
import { clsx } from 'clsx';
import React from 'react';

import Radio from './Radio';
import { InputProps } from './types';
import { Option } from '../../../../common/types';

export type RadioGroupProps<T> = InputProps & {
  value?: T;
  disabled?: boolean;
  options: Option<T>[];
  onChange?: (value: T) => void;
};

const RadioGroup = <T extends string>({
  error,
  label,
  value,
  options,
  disabled,
  onChange,
  className,
}: RadioGroupProps<T>) => {
  return (
    <EpRadioGroup
      value={value}
      onChange={onChange}
      className={clsx('flex flex-col gap-1', className)}
    >
      {label &&
        (typeof label === 'string' ? (
          <Label className="caption text-xs">{label}</Label>
        ) : (
          label
        ))}
      {options.map(({ label, value }) => (
        <Radio key={value} value={value} label={label} disabled={disabled} />
      ))}
      {error && (
        <p className="caption ml-1 mt-1 text-sm text-appRose">{error}</p>
      )}
    </EpRadioGroup>
  );
};

export default RadioGroup;
