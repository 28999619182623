import { AxiosResponse } from 'axios';

import { PaginatedResult, Position, PositionFilter } from 'common/models';
import http from 'core/services/api';

import { POSITIONS } from './mockData';

class PositionsRepository {
  getPositionsMocked = async ({
    sessionId = '0',
    ticker,
    status,
    page = 1,
    perPage = 10,
  }: PositionFilter) =>
    http.fetch(async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      let filteredData = POSITIONS;

      if (sessionId) {
        filteredData = filteredData.filter(
          (position) => position.sessionId === sessionId
        );
      }

      if (ticker) {
        filteredData = filteredData.filter(
          (position) => position.ticker === ticker
        );
      }

      if (status) {
        filteredData = filteredData.filter(
          (position) => position.status === status
        );
      }

      const total = filteredData.length;
      const startIndex = (page - 1) * perPage;
      const paginatedItems = filteredData.slice(
        startIndex,
        startIndex + perPage
      );

      return {
        data: {
          total,
          filtered: paginatedItems.length,
          items: paginatedItems,
        } as PaginatedResult<Position>,
        status: 200,
        statusText: '200',
        headers: {},
        config: {},
      } as AxiosResponse<PaginatedResult<Position> | null>;
    });

  getPositions = async ({
    page = 1,
    perPage = 10,
    ...params
  }: PositionFilter) =>
    http.fetch(async (axios, signal) => {
      return axios.get<PaginatedResult<Position>>(`/positions/filter`, {
        signal,
        params: {
          ...params,
          page,
          perPage,
        },
      });
    });
}

export const positionsRepository = new PositionsRepository();
