import { t } from 'i18next';
import * as yup from 'yup';

import { createPasswordInputSchema } from 'core/components/form';

export const changePasswordSchema = yup
  .object({
    prevPassword: createPasswordInputSchema({
      name: t('pages.settings.changePasswordForm.fields.prevPassword.name'),
    }),
    newPassword: createPasswordInputSchema({
      name: t('pages.settings.changePasswordForm.fields.newPassword.name'),
    }),
  })
  .required();

export type ChangePasswordFormData = yup.InferType<typeof changePasswordSchema>;
