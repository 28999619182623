import { Input } from '@headlessui/react';
import { clsx } from 'clsx';
import React, {
  forwardRef,
  InputHTMLAttributes,
  ReactNode,
  useCallback,
} from 'react';

import { InputProps } from './types';

export type DefaultInputProps = Pick<InputProps, 'className'> &
  InputHTMLAttributes<HTMLInputElement> & {
    renderIcon?: () => ReactNode;
    prefix?: ReactNode;
    suffix?: ReactNode;
    format?: (value: string) => string;
    unformat?: (value: string) => string;
  };

// eslint-disable-next-line react/display-name
const DefaultInput = forwardRef<HTMLInputElement, DefaultInputProps>(
  (
    {
      className,
      renderIcon,
      prefix,
      suffix,
      unformat,
      format,
      onChange,
      value,
      ...rest
    },
    ref
  ) => {
    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const rawValue = e.target.value;

        const unformattedValue = unformat ? unformat(rawValue) : rawValue;

        const formattedValue = format
          ? format(unformattedValue)
          : unformattedValue;

        if (onChange) {
          onChange({
            ...e,
            target: { ...e.target, value: unformattedValue },
          });
        }

        e.target.value = formattedValue;
      },
      [format, unformat, onChange]
    );

    const formattedValue = format ? format(String(value || '')) : value;

    return (
      <div className="relative flex items-center">
        {prefix && (
          <div className="absolute left-2 text-slate-500">{prefix}</div>
        )}
        <Input
          ref={ref}
          onWheel={(e) => e.currentTarget.blur()}
          {...rest}
          value={formattedValue}
          onChange={handleChange}
          className={clsx(
            'w-full rounded-md border-[1px] border-slate-400 bg-slate-100 px-2 py-2 text-base focus:border-blue-500 focus:outline-none disabled:bg-slate-200',
            prefix && 'pl-10',
            suffix && 'pr-10',
            className
          )}
        />

        {renderIcon ? (
          <div className="absolute right-3 top-1/2 -translate-y-1/2 transform">
            {renderIcon()}
          </div>
        ) : suffix ? (
          <div className="absolute right-2 text-slate-500">{suffix}</div>
        ) : null}
      </div>
    );
  }
);

export default DefaultInput;
