import { ColumnDef, PaginationState } from '@tanstack/react-table';
import React, { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { OrderFilter, Order } from 'common/models';
import {
  Table,
  enumCell,
  dateCell,
  valueCell,
  numberCell,
  CircularIndicator,
  TableProps,
} from 'core/components/uikit';

import CancelOrderButton from './CancelOrderButton';
import { useFetchOrdersWithTimer } from './useFetchOrders';
import DisclosureWithTitle from '../DisclosureWithTitle';

export type OrdersTableProps = {
  filters?: OrderFilter;
  title?: string;
};

export const OrdersTable = memo(({ filters = {}, title }: OrdersTableProps) => {
  const { t } = useTranslation();
  const { data, isLoading, fetch, startTimer, stopTimer, args } =
    useFetchOrdersWithTimer(filters);

  const refetch = useCallback(async () => {
    if (args) await fetch(args);
  }, [args]);

  const columns = useMemo<ColumnDef<Order>[]>(
    () => [
      {
        header: t('components.ordersTable.headers.token'),
        accessorKey: 'token',
        cell: valueCell(),
      },
      {
        header: t('components.ordersTable.headers.status'),
        accessorKey: 'status',
        cell: enumCell(),
      },
      {
        id: 'cancel-order',
        header: t('components.ordersTable.headers.cancel'),
        cell: ({ row }) => {
          const { id, status } = row.original;
          const showCancelButton = status === 'placed';

          return showCancelButton ? (
            <CancelOrderButton id={id ?? ''} refetchTable={refetch} />
          ) : (
            '-'
          );
        },
      },
      {
        header: t('components.ordersTable.headers.side'),
        accessorKey: 'side',
        cell: enumCell(),
      },
      {
        header: t('components.ordersTable.headers.type'),
        accessorKey: 'type',
        cell: enumCell(),
      },
      {
        header: t('components.ordersTable.headers.priceType'),
        accessorKey: 'priceType',
        cell: enumCell(),
      },
      {
        header: t('components.ordersTable.headers.entryPrice'),
        accessorKey: 'entryPrice',
        cell: numberCell({ prefix: '$' }),
      },
      {
        header: t('components.ordersTable.headers.executedPrice'),
        accessorKey: 'executedPrice',
        cell: numberCell({ prefix: '$' }),
      },
      {
        header: t('components.ordersTable.headers.quantity'),
        accessorKey: 'quantity',
        cell: numberCell({ fractionDigits: 0 }),
      },
      {
        header: t('components.ordersTable.headers.executedQuantity'),
        accessorKey: 'executedQuantity',
        cell: numberCell({ fractionDigits: 0 }),
      },
      {
        header: t('components.ordersTable.headers.closeTriggerType'),
        accessorKey: 'closeTriggerType',
        cell: enumCell(),
      },
      {
        header: t('components.ordersTable.headers.sentDate'),
        accessorKey: 'sentDate',
        cell: (cellProps) => (
          <div className="whitespace-normal">{dateCell()(cellProps)}</div>
        ),
        minSize: 120,
      },
      {
        header: t('components.ordersTable.headers.filledDate'),
        accessorKey: 'filledDate',
        cell: (cellProps) => (
          <div className="whitespace-normal">{dateCell()(cellProps)}</div>
        ),
        minSize: 120,
      },
      {
        header: t('components.ordersTable.headers.updatedDate'),
        accessorKey: 'updatedDate',
        cell: (cellProps) => (
          <div className="whitespace-normal">{dateCell()(cellProps)}</div>
        ),
        minSize: 120,
      },
    ],
    [t, refetch]
  );

  const onPaginationChanged = useCallback(
    async ({ pageIndex, pageSize }: PaginationState) => {
      const newArgs = {
        ...(args ?? {}),
        page: pageIndex + 1,
        perPage: pageSize,
      };
      await fetch(newArgs);
    },
    [args]
  );

  const toogleTimer = useCallback(
    (open: boolean) => {
      if (open) {
        stopTimer();
      } else {
        startTimer(5000);
      }
    },
    [stopTimer, startTimer]
  );

  const rowClassName: TableProps<Order>['rowClassName'] = (row) => {
    const { status, side } = row.original;

    switch (status) {
      case 'canceled':
        return 'bg-yellow-400 bg-opacity-30';
      case 'rejected':
        return 'bg-yellow-600 bg-opacity-30';

      case 'placed':
      case 'canceling':
        return 'bg-gray-100';

      case 'executing':
        return 'bg-blue-500 bg-opacity-20';

      case 'executed':
        switch (side) {
          case 'short':
          case 'sell':
            return 'bg-red-500 bg-opacity-20';

          case 'buy':
            return 'bg-green-500 bg-opacity-20';
        }
        return '';

      default:
        return '';
    }
  };

  return (
    <DisclosureWithTitle
      defaultOpen={false}
      title={
        <div className="flex flex-row items-center gap-2">
          <h4>{title ?? t('components.ordersTable.title')}</h4>
          {isLoading && <CircularIndicator size={24} />}
        </div>
      }
      onClick={toogleTimer}
    >
      <Table
        data={data?.items ?? []}
        columns={columns}
        totalCount={data?.filtered ?? 0}
        onPaginationChanged={onPaginationChanged}
        options={{
          initialState: {
            pagination: {
              pageIndex: (args?.page ?? 1) - 1,
              pageSize: args?.perPage,
            },
          },
        }}
        rowClassName={rowClassName}
      />
    </DisclosureWithTitle>
  );
});

OrdersTable.displayName = 'OrdersTable';

export default OrdersTable;
