import { XMarkIcon } from '@heroicons/react/20/solid';
import React, { FC } from 'react';

import { AsyncCallback } from 'common/types';
import { CircularIndicator } from 'core/components/uikit';

import { useCancelTask } from './useFetchTasks';

export type CancelTaskButtonProps = {
  id: string;
  refetchTable: AsyncCallback;
};

const CancelTaskButton: FC<CancelTaskButtonProps> = ({ id, refetchTable }) => {
  const { fetch, isLoading } = useCancelTask({ id });

  return (
    <button
      className="button-s h-8 bg-red-700 px-2"
      onClick={async () => {
        await fetch({ id });
        await refetchTable();
      }}
      disabled={isLoading}
    >
      {isLoading ? <CircularIndicator /> : <XMarkIcon className="size-5" />}
    </button>
  );
};

export default CancelTaskButton;
