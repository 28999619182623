import { clsx } from 'clsx';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const STATUSES = {
  ERROR: 'error',
  ACTIVE: 'active',
  PAUSED: 'paused',
  STOPPED: 'stopped',
} as const;
export type Statuses = (typeof STATUSES)[keyof typeof STATUSES];

export type StatusTextProps = {
  status: Statuses | null;
  prefix: string;
};

const StatusText: FC<StatusTextProps> = ({ status, prefix }) => {
  const { t } = useTranslation();

  return (
    <p className="flex w-72 flex-row justify-between gap-2">
      {prefix}:{' '}
      {status && (
        <b
          className={clsx('font-bold', {
            'text-green-500': status === STATUSES.ACTIVE,
            'text-yellow-500':
              status === STATUSES.STOPPED || status === STATUSES.PAUSED,
            'text-red-500': status === STATUSES.ERROR,
          })}
        >
          {t(`components.statuses.${status}`)}
        </b>
      )}
    </p>
  );
};

export default StatusText;
