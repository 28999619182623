import React from 'react';
import { useTranslation } from 'react-i18next';

import { CircularIndicator } from 'core/components/uikit';

import { useExportOrders } from './useExportOrders';

export type ExportOrdersButtonProps = {
  sessionId: string;
};

const ExportOrdersButton = ({ sessionId }: ExportOrdersButtonProps) => {
  const { t } = useTranslation();
  const { exportOrders, isLoading } = useExportOrders();

  return (
    <button
      className="outlined-button-s"
      onClick={() =>
        exportOrders({
          sessionId,
        })
      }
      disabled={isLoading}
    >
      {isLoading && <CircularIndicator size={24} className="mr-2" />}
      {t('components.positionsTable.filters.export')}
    </button>
  );
};

export default ExportOrdersButton;
