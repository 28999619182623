import React from 'react';
import { useTranslation } from 'react-i18next';

import { Wrapper } from 'core/components/common';

import AppSettings from './AppSettings';
import ChangePasswordForm from './ChangePasswordForm';
import ChangePinForm from './ChangePinForm';
import TradeSettingsForm from './TradeSettingsForm';

const Settings = () => {
  const { t } = useTranslation();

  return (
    <Wrapper internalClassName="flex flex-col gap-6">
      <h2 className="mb-6">{t('pages.settings.title')}</h2>
      <AppSettings />
      <TradeSettingsForm />
      <ChangePasswordForm />
      <ChangePinForm />
    </Wrapper>
  );
};

export default Settings;
