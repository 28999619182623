import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CodeInput } from 'core/components/form';
import { CircularIndicator } from 'core/components/uikit';
import { DisclosureWithTitle } from 'features/components';

import { ChangePinCodeFormData, changePinCodeSchema } from './validationSchema';

const defaultValues = {
  newCode: '',
  prevCode: '',
};

const ChangePinForm = () => {
  const { t } = useTranslation();

  const {
    reset,
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<ChangePinCodeFormData>({
    resolver: yupResolver(changePinCodeSchema),
    mode: 'all',
    defaultValues,
  });

  const isButtonDisabled = !isValid || isSubmitting;

  const onSubmit = handleSubmit((values) => {
    console.log(values);
    reset(defaultValues);
  });

  return (
    <DisclosureWithTitle
      title={<h4>{t('pages.settings.changePinForm.title')}</h4>}
      defaultOpen
    >
      <form
        className="flex max-w-full flex-col sm:max-w-80"
        onSubmit={onSubmit}
      >
        <CodeInput
          name="prevCode"
          control={control}
          componentProps={{
            numInputs: 4,
            label: t('pages.settings.changePinForm.fields.prevPin.label'),
          }}
        />
        <CodeInput
          name="newCode"
          control={control}
          componentProps={{
            numInputs: 4,
            label: t('pages.settings.changePinForm.fields.newPin.label'),
          }}
        />
        <button type="submit" disabled={isButtonDisabled}>
          {isSubmitting ? <CircularIndicator /> : t('common.save')}
        </button>
      </form>
    </DisclosureWithTitle>
  );
};

export default ChangePinForm;
