const locale = {
  translation: {
    common: {
      na: 'N/A',
      yes: 'Yes',
      done: 'Done',
      stop: 'Stop',
      save: 'Save',
      login: 'Login',
      start: 'Start',
      delete: 'Delete',
      cancel: 'Cancel',
      logout: 'Logout',
      search: 'Search',
      continue: 'Continue',
    },
    noData: {
      general: 'No data',
      actions: 'No actions',
      session: 'No session details',
      tickers: 'No tickers',
      positions: 'No positions',
      orders: 'No orders',
    },
    components: {
      appLogo: 'Trading Bot App',
      sessionStatuses: {
        bot: 'BOT Status',
        rth: 'RTH Status',
        prehours: 'Pre-Market Status',
        afterhours: 'After-Hours Status',
      },
      durationInput: {
        seconds: 'seconds',
        minutes: 'minutes',
        hours: 'hours',
        days: 'days',
      },
      footer: {
        copyRights: '© {{year}} Trading Bot Inc. | All Rights Reserved',
      },
      header: {
        settings: 'Settings',
        reports: 'Reports',
        sessions: 'Sessions',
        newSession: 'New Session',
      },
      statuses: {
        error: 'Error',
        active: 'Active',
        paused: 'Paused',
        stopped: 'Stopped',
      },
      confirmActionDialog: {
        validationRequired: 'Validation required',
        toDoSomethingWithSession:
          'To {{action}} the session, please enter your PIN.',
        actions: {
          stop: 'STOP',
          edit: 'EDIT',
          start: 'START',
          create: 'CREATE',
          delete: 'DELETE',
        },
      },
      lastActionsTable: {
        filters: {
          status: {
            label: 'Status',
            all: 'All',
            status: 'Status',
            alerts: 'Alerts',
            trigger: 'Trigger',
            trades: 'Trades',
            errors: 'Errors',
          },
        },
        headers: {
          type: 'Type',
          text: 'Text',
          date: 'Date',
        },
      },
      table: {
        page: 'Page {{current}} of {{of}}',
        total: 'Total: {{total}}',
        number: '№',
      },
      positionsTable: {
        title: 'Positions',
        filters: {
          ticker: 'Ticker',
          export: 'Export',
          status: {
            label: 'Status',
            all: 'All',
            open: 'Open',
            pending: 'Pending',
            closed: 'Closed',
          },
          locateType: {
            label: 'Locate Type',
            all: 'All',
            etb: 'ETB',
            htb: 'HTB',
          },
        },
        headers: {
          type: 'Type',
          tiker: 'Ticker',
          status: 'Status',
          quantity: 'Quantity',
          openDate: 'Open date',
          avgPrice: 'AVG price',
          closeDate: 'Close date',
          locateType: 'Locate type',
          marketType: 'Market type',
          currentSize: 'Position size',
          currentPrice: 'Crrent price',
          stopLossPrice: 'Stop loss price',
          takeProfitPrice: 'Take profit price',
          executedQuantity: 'Executed quantity',
        },
      },
      ordersTable: {
        title: 'Orders',
        headers: {
          type: 'Type',
          side: 'Side',
          token: 'Token',
          status: 'Status',
          quantity: 'Quantity',
          sentDate: 'Sent date',
          cancel: 'Cancel Order',
          priceType: 'Price type',
          entryPrice: 'Entry price',
          filledDate: 'Filled date',
          updatedDate: 'Updated date',
          executedPrice: 'Executed price',
          executedQuantity: 'Executed quantity',
          closeTriggerType: 'Close trigger type',
        },
      },
      tasksTable: {
        title: 'Tasks',
        headers: {
          status: 'Status',
          cancel: 'Cancel Task',
          triggeringTime: 'Triggering Time',
          sizeOfClosingPositionPart: 'Close %',
          orderType: 'Order Type',
          orderPriceType: 'Order Price Type',
          entriesCount: 'Entries Count',
          openEntryInterval: 'Open Entry Interval',
          orderRefreshInterval: 'Order Refresh Interval',
          openEntryLimit: 'Open Entry Limit',
          exitDelay: 'Exit Delay',
        },
      },
      tickersTable: {
        title: 'Tickers',
        titles: {
          rth: 'RTH',
          prehours: 'Pre-Market',
          afterhours: 'After-Hours',
        },
        headers: {
          ticker: 'Ticker',
          price: 'Price',
          marketType: 'Market Type',
          marketCap: 'Market Cap',
          listType: 'List Type',
          prevCloseTrigger: 'Prev Close Trigger',
          lastPeriodTrigger: 'Last Period Trigger',
          currentDayVolume: 'Current Day Volume',
          lastPeriodVolume: 'Last Period Volume',
          lastPeriodMinutes: 'Last Period',
          priceChangePercent: 'Prev Close Price Change',
          filtersData: 'Filters Data',
          triggerData: 'Triggers Data',
          updatedAt: 'Updated At',
        },
        filters: {
          ticker: 'Ticker',
          listType: {
            all: 'All',
            label: 'List type',
            watchlist: 'Watchlist',
            preWatchlist: 'Pre watchlist',
          },
          marketType: {
            all: 'All',
            label: 'Market type',
            ahMarket: 'AH Market',
            phMarket: 'PH Market',
            rthMarket: 'RTH Market',
          },
        },
      },
    },
    pages: {
      sessions: {
        title: 'Strategy Sessions',
        subtitle: 'Here you can view all your current trading sessions.',
        createSession: 'Create Session',
        editSession: 'Edit Session',
        sessionDetails: 'Session Details',
      },
      sessionDetails: {
        systemLogs: 'System Logs',
        sessionParams: 'Session parameters',
      },
      createSession: {
        title: 'Create session',
        action: 'Create session',
        andOr: 'AND / OR',
        sessionName: {
          label: 'Session name',
        },
        isActive: 'Is Active',
        marketHoursRangeHelperText:
          "This slider controls the bot's operating time in the context of opening positions. Please note that if you narrow the slider, the Finder and Monitor services will be halted, and the bot will stop opening new positions. However, any orders already placed may still be executed in DAS platform, and any time-based scheduled tasks will be carried out as planned.",
        rth: {
          title: 'Trade RTH',
          form: {
            marketHoursRange: {
              label: 'Market Hours (9:30 AM – 4:00 PM)',
            },
          },
        },
        prehours: {
          title: 'Trade Pre-Market',
          form: {
            marketHoursRange: {
              label: 'Premarket (4:00 AM – 9:30 AM)',
            },
          },
        },
        afterhours: {
          title: 'Trade After-Hours',
          form: {
            marketHoursRange: {
              label: 'After-Hours (4:00 PM – 8:00 PM)',
            },
          },
        },
        common: {
          form: {
            filterParams: {
              title: 'Filters',
              exchanges: {
                label: 'Exchanges',
                nasdaq: 'NASDAQ',
                nyse: 'NYSE',
              },
              priceChangeFromPrevCloseRange: {
                min: 'Price change from previous close (min)',
                max: 'Price change from previous close (max)',
              },
              marketCapRange: {
                min: 'Market cap in $M (min)',
                max: 'Market cap in $M (max)',
              },
              priceRange: {
                min: 'Price (min)',
                max: 'Price (max)',
              },
              lastPeriod: {
                label: 'Last period',
              },
              currentDayVolumeRange: {
                min: 'Current day $ volume (min)',
                max: 'Current day $ volume (max)',
              },
              lastPeriodVolumeRange: {
                min: 'Last period $ volume (min)',
                max: 'Last period $ volume (max)',
              },
            },
            triggerParams: {
              title: 'Triggers',
              error:
                'You must fill either both "{{field1}}" and "{{field2}}", or "{{field3}}"',
              lastPeriodInterval: {
                name: 'Last period',
                label: 'Last period',
              },
              lastPeriodPriceChange: {
                name: 'Last period price change',
                label: 'Last period price change',
              },
              priceChangeFromPrevClose: {
                name: 'Price change',
                label: 'Price change from previous close',
              },
            },
            openPositionParams: {
              title: 'Open position params',
              maxPositionVolume: {
                name: 'Max position size',
                label: 'Max position size',
              },
              maxEntryVolume: {
                name: 'Max entry size',
                label: 'Max entry size',
              },
              openEntryInterval: {
                name: 'Entry interval',
                label: 'Entry interval',
              },
              maxTickersPositionsPerDay: {
                name: 'Do not trade over limit',
                label: 'Do not trade over limit',
                helperText:
                  "To limit the number of daily positions for a stock, enter the same value in the PH, RTH, and AH fields. Alternatively, you can set different values to apply limits progressively across frames.\n\nFor example, with (PH = 1, RTH = 2, AH = 3):\n\n- A position opens in PH but can't reopen after closure due to the limit.\n- A new position opens in RTH but can't reopen too.\n- Another position is allowed in AH, reaching the final daily limit.",
              },
              orderType: {
                name: 'Order type',
                label: 'Order type',
                market: 'Use market orders',
                limit: 'Use limit orders',
              },
              orderPriceType: {
                name: 'Order price type',
                label: 'Order price type',
                market: 'Use market orders',
                askLimit: 'Use ask-.01 limit orders',
                bidLimit: 'Use bid limit orders',
              },
              orderRefreshInterval: {
                name: 'Refresh interval',
                label: 'Limit order refresh interval',
              },
              openEntryLimit: {
                name: 'Open entry limit',
                label: 'Open entry limit',
              },
              chaseDown: {
                name: 'Chase down',
                label: 'Chase down',
              },
              additionalSteps: {
                add: 'Add additional step',
                title: 'Step {{number}}',
                entrySize: {
                  name: 'Entry size',
                  label: 'Entry size',
                },
                triggeringPriceChange: {
                  name: 'Triggering price change',
                  label: 'Triggering price change',
                },
              },
            },
          },
        },
        closePositionParams: {
          title: 'Close position params',
          helperText:
            'All orders have DAY+ time in force. If you don’t set Time Based parameters, your positions can be held overnight and should be processed manually in Cobra following day.',
          form: {
            stopLossParams: {
              add: 'Add stop loss',
              title: 'Stop loss {{number}}',
              maxPositionVolume: {
                name: 'Max position size',
                label: 'Max position size',
              },
              sizeOfClosingPositionPart: {
                name: 'Сlose',
                label: 'Close',
              },
            },
            takeProfitParams: {
              add: 'Add take profit',
              title: 'Take profit {{number}}',
              triggeringPriceChange: {
                name: 'Triggering price change',
                label: 'Triggering price change',
              },
              sizeOfClosingPositionPart: {
                name: 'Сlose',
                label: 'Close',
              },
            },
            timeBasedParams: {
              add: 'Add time based',
              title: 'Time based {{number}}',
              triggeringTime: {
                name: 'Triggering time',
                label: 'Triggering time',
              },
              sizeOfClosingPositionPart: {
                name: 'Сlose',
                label: 'Close',
              },
              orderType: {
                name: 'Order type',
                label: 'Order type for timed stops',
                market: 'Use market orders',
                limit: 'Use limit orders',
              },
              orderPriceType: {
                name: 'Order price type',
                label: 'Order price type',
                market: 'Use market orders',
                askLimit: 'Use ask limit orders',
                bidLimit: 'Use bid+.01 limit orders',
              },
              entriesCount: {
                name: 'Entries count',
                label: 'Entries count',
              },
              openEntryInterval: {
                name: 'Open entry interval',
                label: 'Open entry interval',
              },
              orderRefreshInterval: {
                name: 'Order refresh interval',
                label: 'Order refresh interval',
              },
              openEntryLimit: {
                name: 'Open entry limit',
                label: 'Open entry limit',
              },
              exitDelay: {
                name: 'Exit delay',
                label: 'Exit delay',
              },
            },
          },
        },
        blacklist: {
          label: 'Blacklist',
          name: 'Blacklist',
          placeholder: 'Add stock to blacklist',
        },
      },
      editSession: {
        actions: {
          saveChanges: 'Save Changes',
          createNew: 'Create New Session - Transfer Parameters',
        },
        openPositions: {
          title: 'Open positions',
          entryText:
            '{{type}}, {{ticker}} ${{total}}, price ${{entryPrice}}, shares to cover {{quantity}}, sold price ${{currentPrice}}, potential Profit or Loss: ${{profitLoss}}.',
        },
        statuses: {
          title: 'Statuses:',
        },
        stopDialog: {
          firstStep: {
            title: '! Active Orders May Still Open !',
            subtitle:
              'Active open orders may still be open when you stop the Bot.  What would you like the Bot to do when we stop the Bot:',
            cancelOrders: {
              buttonText: 'Cancel All Active Orders in Cobra',
              helperText:
                'Bot will cancel all Cobra orders and time based scheduled orders. You will need to self manage your open POSITIONS in Cobra.',
            },
            stopWithoutCanceling: {
              buttonText: 'Do Not Cancel Active Orders in Cobra',
              helperText:
                'All Cobra active orders will remain.  All Bot time based scheduled orders will be canceled. You will need to self mange your open POSITIONS and ORDERS on Cobra.',
            },
          },
          secondStep: {
            title: 'Are you sure you want to:',
            subtitle: {
              cancelOrders: 'Cancel all active orders in Cobra.',
              stopWithoutCanceling: 'Keep all active orders in Cobra.',
            },
            helperText: {
              cancelOrders:
                'Be aware that the cancellation process can take several minutes, depending on the number of placed orders.',
            },
          },
        },
      },
      settings: {
        title: 'Settings',
        changePinForm: {
          title: 'Change PIN:',
          fields: {
            prevPin: {
              name: 'Current PIN Code',
              label: 'Current PIN Code',
            },
            newPin: {
              name: 'New PIN Code',
              label: 'New PIN Code',
            },
          },
        },
        changePasswordForm: {
          title: 'Change Password:',
          fields: {
            prevPassword: {
              name: 'Current Password',
              label: 'Current Password',
            },
            newPassword: {
              name: 'New Password',
              label: 'New Password',
            },
          },
        },
        tradeSettingsForm: {
          title: 'Trade Settings:',
          fields: {
            maxNewPositionsPerDay: {
              name: 'Max new positions per day',
              label: 'Max new positions per day',
            },
            maxTotalPositions: {
              name: 'Max total positions',
              label: 'Max total positions',
            },
            doNotTradeOverLimit: {
              name: 'Do not trade over limit',
              label: 'Do not trade over limit',
            },
          },
        },
        appSettings: {
          title: 'App Settings:',
          fields: {
            showSnackbarErrorMessages: {
              label: 'Show snackbar error messages',
            },
          },
        },
      },
      reports: {
        title: 'Reports',
      },
      phone: {
        fields: {
          phone: 'Phone',
          password: 'Password',
        },
      },
      verifyPhone: {
        fields: {
          otpCode: 'OTP Code',
        },
        action: 'Continue',
        helperText:
          'Please enter the OTP code sent to your phone. The code is valid for 2 minutes. If you didn’t receive the code, <button>click here</button> to resend it again',
      },
      pinCodeForm: {
        fields: {
          pinCode: 'PIN Code',
        },
        action: 'CONFIRM',
        helperText: 'Please enter your PIN code',
      },
    },
    notify: {
      resendCode: 'Confirmation code was sent successfully',
    },
    errors: {
      api: {
        notAuthorized: 'Not authorized',
        other: 'Something went wrong',
        notFound: 'Not found',
      },
      validation: {
        isRequired: '{{name}} is required',
        atLeastOne: 'At least one value must be selected',
        isInvalid: '{{name}} is invalid',
        minLength: '{{name}} should be at least {{length}}',
        maxLength: '{{name}} should be at most {{length}}',
        cantBeGreater: '{{name}} cannot be greater than {{length}}',
        cantBeLess: '{{name}} cannot be less than {{length}}',
        mustBe: '{{name}} must be a {{type}}',
        range: {
          mustIncludeBoth:
            '{{name}} must include both minimum and maximum values.',
        },
        password: {
          uppercase: '{{name}} must contain at least one uppercase letter',
          lowercase: '{{name}} must contain at least one lowercase letter',
          number: '{{name}} must contain at least one number',
          specialCharacter:
            '{{name}} must contain at least one special character (@, $, !, %, *, ?, &, _, -)',
        },
        triggers: {
          atLeastOne: 'At least one required',
        },
        timePeriod: 'At least one time period must be selected',
        time: 'Please enter a valid time (e.g., 2:00 PM)',
      },
    },
  },
};

export default locale;
