import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MarketType } from 'common/models';
import { Option } from 'common/types';
import { Select } from 'core/components/uikit';

export type MarketTypeFilterOption = Option<MarketType | null>;

export type MarketTypeSelectInputProps = {
  type: string | undefined;
  onChange: (value: MarketType | undefined) => void;
};

const MarketTypeSelectInput: FC<MarketTypeSelectInputProps> = ({
  type,
  onChange,
}) => {
  const { t } = useTranslation();

  const filterOptions = useMemo<MarketTypeFilterOption[]>(
    () => [
      {
        value: null,
        label: t('components.tickersTable.filters.marketType.all'),
      },
      {
        value: 'ahMarket',
        label: t('components.tickersTable.filters.marketType.ahMarket'),
      },
      {
        value: 'phMarket',
        label: t('components.tickersTable.filters.marketType.phMarket'),
      },
      {
        value: 'rthMarket',
        label: t('components.tickersTable.filters.marketType.rthMarket'),
      },
    ],
    [t]
  );

  const value =
    filterOptions.find((item) => item.value === type) ?? filterOptions[0];

  return (
    <Select
      value={value}
      className="max-w-52"
      label={t('components.tickersTable.filters.marketType.label')}
      onChange={(newValue) =>
        onChange((newValue as MarketTypeFilterOption).value ?? undefined)
      }
      options={filterOptions}
    />
  );
};

export default MarketTypeSelectInput;
