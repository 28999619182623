import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Tooltip } from 'core/components/uikit';
import { DisclosureWithTitle } from 'features/components';

import StopLossInputs from './StopLossInputs';
import TakeProfitInputs from './TakeProfitInputs';
import TimeBasedInputs from './TimeBasedInputs';
import { CommonInputsProps } from './types';

const ClosePositionInputs: FC<CommonInputsProps> = (props) => {
  const { t } = useTranslation();

  return (
    <DisclosureWithTitle
      title={
        <h4 className="flex flex-row items-center justify-center gap-1">
          {t('pages.createSession.closePositionParams.title')}
          <Tooltip
            text={t('pages.createSession.closePositionParams.helperText')}
            position="top"
          />
        </h4>
      }
      defaultOpen={true}
    >
      <div className="flex flex-col gap-6">
        <StopLossInputs {...props} />
        <hr className="border-t border-gray-300" />
        <TakeProfitInputs {...props} />
        <hr className="border-t border-gray-300" />
        <TimeBasedInputs {...props} />
      </div>
    </DisclosureWithTitle>
  );
};

export default ClosePositionInputs;
