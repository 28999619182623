function clearNullFieldsFromObject<T>(obj: T): T {
  if (Array.isArray(obj)) {
    // If the object is an array, return it as is without modification
    return obj;
  } else if (typeof obj === 'object' && obj !== null) {
    // Create a copy of the object to ensure type safety
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: Record<string, any> = { ...obj };

    // Iterate over the properties of the object
    for (const key in result) {
      if (result[key] === null || result[key] === undefined) {
        delete result[key];
      } else if (
        typeof result[key] === 'object' &&
        !Array.isArray(result[key])
      ) {
        // Recursively remove null fields from nested objects, but ignore arrays
        result[key] = clearNullFieldsFromObject(result[key]);
      }
    }

    return result as T;
  }

  return obj;
}

export default clearNullFieldsFromObject;
