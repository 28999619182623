import { DialogPanel, DialogTitle, Description } from '@headlessui/react';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { StopBehavior } from 'common/models';
import { Callback } from 'common/types';
import { WithConfirmActionDialogSharedProps } from 'features/components';

import useEditSessionStore from '../editSessionStore';

type StopSessionContentProps = {
  onClose: Callback;
  stopBehavior: StopBehavior;
  setStopBehavior: Dispatch<SetStateAction<StopBehavior | undefined>>;
  showConfirmActionDialog: WithConfirmActionDialogSharedProps['showConfirmActionDialog'];
};

const StopSessionContent: FC<StopSessionContentProps> = ({
  onClose,
  stopBehavior,
  setStopBehavior,
  showConfirmActionDialog,
}) => {
  const { t } = useTranslation();

  const onStop = async () => {
    onClose();

    await showConfirmActionDialog({
      onConfirm: async () => {
        await useEditSessionStore.getState().stopSession({ stopBehavior });
      },
      title: t('components.confirmActionDialog.toDoSomethingWithSession', {
        action: t('components.confirmActionDialog.actions.stop'),
      }),
    });
  };

  return (
    <DialogPanel className="max-w-lg rounded-md bg-white p-12">
      <DialogTitle className="mb-4 text-center text-2xl">
        {t('pages.editSession.stopDialog.secondStep.title')}
      </DialogTitle>
      <Description className="text-center text-red-700">
        {t(
          stopBehavior === 'none'
            ? 'pages.editSession.stopDialog.secondStep.subtitle.stopWithoutCanceling'
            : 'pages.editSession.stopDialog.secondStep.subtitle.cancelOrders'
        )}
      </Description>
      <div className="mt-8 flex flex-col gap-2">
        <button onClick={onStop}>{t('common.yes')}</button>
        <button
          onClick={() => setStopBehavior(undefined)}
          className="outlined-button mt-2"
        >
          {t('common.cancel')}
        </button>
      </div>
      {stopBehavior === 'cancel_executing' && (
        <Description className="caption mt-4 text-center text-sm">
          {t('pages.editSession.stopDialog.secondStep.helperText.cancelOrders')}
        </Description>
      )}
    </DialogPanel>
  );
};

export default StopSessionContent;
