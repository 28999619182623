import React, { FC } from 'react';

import { useQueryString } from 'common/hooks';
import { useAuthStore } from 'common/stores';
import { Wrapper } from 'core/components/common';
import { PhoneQueryParams } from 'core/router';
import { PhoneVerifyForm, PhoneVerifyFormProps } from 'features/components';

const PhoneVerify: FC = () => {
  const {
    params: { phone },
  } = useQueryString<PhoneQueryParams>();

  const onSubmit: PhoneVerifyFormProps['onSubimit'] = async ({ code }) => {
    const authStore = useAuthStore.getState();

    const account = await authStore.verifyOtpCode({
      phone: parseInt(phone),
      otp: parseInt(code),
    });

    if (!account) return;
  };

  return (
    <Wrapper internalClassName="!max-w-md">
      <PhoneVerifyForm phone={phone} onSubimit={onSubmit} />
    </Wrapper>
  );
};

export default PhoneVerify;
