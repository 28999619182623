import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TextInput } from 'core/components/form';
import { CircularIndicator } from 'core/components/uikit';
import { DisclosureWithTitle } from 'features/components';

import {
  ChangePasswordFormData,
  changePasswordSchema,
} from './validationSchema';

const defaultValues = {
  prevPassword: '',
  newPassword: '',
};

const ChangePasswordForm = () => {
  const { t } = useTranslation();

  const {
    reset,
    control,
    handleSubmit,
    formState: { isValid, isSubmitting },
  } = useForm<ChangePasswordFormData>({
    resolver: yupResolver(changePasswordSchema),
    defaultValues,
    mode: 'all',
  });

  const isButtonDisabled = !isValid || isSubmitting;

  const onSubmit = handleSubmit((values) => {
    console.log(values);
    reset(defaultValues);
  });

  return (
    <DisclosureWithTitle
      title={<h4>{t('pages.settings.changePasswordForm.title')}</h4>}
      defaultOpen
    >
      <form
        className="flex max-w-full flex-col gap-4 sm:max-w-80"
        onSubmit={onSubmit}
      >
        <TextInput
          name="prevPassword"
          control={control}
          componentProps={{
            type: 'password',
            label: t(
              'pages.settings.changePasswordForm.fields.prevPassword.label'
            ),
          }}
        />
        <TextInput
          name="newPassword"
          control={control}
          componentProps={{
            type: 'password',
            label: t(
              'pages.settings.changePasswordForm.fields.newPassword.label'
            ),
          }}
        />
        <button type="submit" disabled={isButtonDisabled}>
          {isSubmitting ? <CircularIndicator /> : t('common.save')}
        </button>
      </form>
    </DisclosureWithTitle>
  );
};

export default ChangePasswordForm;
