import { DialogPanel, DialogTitle, Description } from '@headlessui/react';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { StopBehavior } from 'common/models';
import { Callback } from 'common/types';

type ChooseStopBehaviorContentProps = {
  onClose: Callback;
  setStopBehavior: Dispatch<SetStateAction<StopBehavior | undefined>>;
};

const ChooseStopBehaviorContent: FC<ChooseStopBehaviorContentProps> = ({
  onClose,
  setStopBehavior,
}) => {
  const { t } = useTranslation();

  return (
    <DialogPanel className="max-w-lg rounded-md bg-white p-12">
      <DialogTitle className="mb-4 text-center text-2xl text-red-700">
        {t('pages.editSession.stopDialog.firstStep.title')}
      </DialogTitle>
      <Description className="text-center">
        {t('pages.editSession.stopDialog.firstStep.subtitle')}
      </Description>
      <div className="mt-8 flex flex-col gap-4">
        <div className="flex w-full flex-col gap-1">
          <button onClick={() => setStopBehavior('cancel_executing')}>
            {t(
              'pages.editSession.stopDialog.firstStep.cancelOrders.buttonText'
            )}
          </button>
          <p className="caption text-center text-sm text-red-700">
            {t(
              'pages.editSession.stopDialog.firstStep.cancelOrders.helperText'
            )}
          </p>
        </div>
        <div className="flex w-full flex-col gap-1">
          <button onClick={() => setStopBehavior('none')}>
            {t(
              'pages.editSession.stopDialog.firstStep.stopWithoutCanceling.buttonText'
            )}
          </button>
          <p className="caption text-center text-sm text-red-700">
            {t(
              'pages.editSession.stopDialog.firstStep.stopWithoutCanceling.helperText'
            )}
          </p>
        </div>
        <button onClick={onClose} className="outlined-button mt-2">
          {t('common.cancel')}
        </button>
      </div>
    </DialogPanel>
  );
};

export default ChooseStopBehaviorContent;
