import {
  CreateEditSessionBody,
  EditSessionBody,
  PaginatedResult,
  Session,
  SessionFilters,
  StopSessionBody,
  Ticker,
  TickersFilter,
} from 'common/models';
import http from 'core/services/api';

class SessionsRepository {
  getSessions = async ({
    page = 1,
    perPage = 20,
    ...params
  }: SessionFilters | undefined = {}) =>
    http.fetch(async (axios, signal) => {
      return axios.get<PaginatedResult<Session>>(`/strategies/filter`, {
        signal,
        params: {
          page,
          perPage,
          ...params,
        },
      });
    });

  getSessionTickersById = async ({
    sessionId,
    page = 1,
    perPage = 10,
    ...params
  }: TickersFilter) =>
    http.fetch(async (axios, signal) => {
      return axios.get<PaginatedResult<Ticker>>(
        `/strategies/${sessionId}/tickers/filter`,
        {
          signal,
          params: {
            ...params,
            perPage,
            page,
          },
        }
      );
    });

  getSessionById = async ({ sessionId }: { sessionId: string }) =>
    http.fetch(async (axios, signal) => {
      return axios.get<Session>(`/strategies/${sessionId ?? '0'}`, {
        signal,
      });
    });

  createSession = async (body: CreateEditSessionBody) =>
    http.fetch(async (axios, signal) => {
      return axios.post<Session>('/strategies', body, {
        signal,
      });
    });

  editSession = async ({ sessionId, body }: EditSessionBody) =>
    http.fetch(async (axios, signal) => {
      return axios.patch<Session>(`/strategies/${sessionId}`, body, {
        signal,
      });
    });

  startSession = async ({ sessionId }: { sessionId: string }) =>
    http.fetch(async (axios, signal) => {
      return axios.patch<void>(
        `/strategies/${sessionId}/start-session`,
        {},
        {
          signal,
        }
      );
    });

  stopSession = async ({ sessionId, stopBehavior }: StopSessionBody) =>
    http.fetch(
      async (axios, signal) => {
        return axios.patch<void>(
          `/strategies/${sessionId}/stop-session`,
          {
            stopBehavior,
          },
          {
            signal,
          }
        );
      },
      false,
      true
    );

  deleteSession = async ({ sessionId }: { sessionId: string }) =>
    http.fetch(async (axios, signal) => {
      return axios.delete<void>(`/strategies/${sessionId}`, {
        signal,
      });
    });
}

export const sessionsRepository = new SessionsRepository();
