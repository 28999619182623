import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { formatNumber, unformatNumber } from 'common/utils';
import { DurationInput, TextInput } from 'core/components/form';
import { DisclosureWithTitle } from 'features/components';

import { CommonInputsProps } from './types';
import { CreateEditSessionFormData } from '../../validationSchema';

const TriggerInputs: FC<CommonInputsProps> = ({
  control,
  basename,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const {
    trigger,
    formState: { errors },
  } = useFormContext<CreateEditSessionFormData>();

  const triggerParamsError = errors?.[basename]?.triggerParams?.root
    ?.message as string | undefined;

  const handleTriggerValidation = () => {
    trigger(`${basename}.triggerParams`);
  };

  return (
    <DisclosureWithTitle
      title={
        <h4>{t('pages.createSession.common.form.triggerParams.title')}</h4>
      }
      defaultOpen={true}
    >
      <DurationInput
        control={control}
        name={`${basename}.triggerParams.lastPeriodInterval`}
        componentProps={{
          disabled,
          label: t(
            'pages.createSession.common.form.triggerParams.lastPeriodInterval.label'
          ),
        }}
      />
      <TextInput
        control={control}
        name={`${basename}.triggerParams.lastPeriodPriceChange`}
        componentProps={{
          disabled,
          suffix: '%',
          label: t(
            'pages.createSession.common.form.triggerParams.lastPeriodPriceChange.label'
          ),
          onChange: handleTriggerValidation,
          format: formatNumber,
          unformat: unformatNumber,
        }}
      />
      {triggerParamsError ? (
        <p className="caption ml-1 mt-1 text-center text-sm text-appRose">
          {triggerParamsError}
        </p>
      ) : (
        <p className="caption text-center text-[12px]">
          {t('pages.createSession.andOr')}
        </p>
      )}
      <TextInput
        control={control}
        name={`${basename}.triggerParams.priceChangeFromPrevClose`}
        componentProps={{
          disabled,
          suffix: '%',
          label: t(
            'pages.createSession.common.form.triggerParams.priceChangeFromPrevClose.label'
          ),
          onChange: handleTriggerValidation,
          format: formatNumber,
          unformat: unformatNumber,
        }}
      />
    </DisclosureWithTitle>
  );
};

export default TriggerInputs;
