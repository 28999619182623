import {
  SendOtpCodeBody,
  VerifyOtpCodeBody,
  ResendOtpCodeBody,
  Tokens,
  RefreshTokensBody,
} from 'common/models';
import http from 'core/services/api';

class AuthRepository {
  sendOtpCode = async (body: SendOtpCodeBody) =>
    http.fetch((axios, signal) =>
      axios.post<object>('/auth/sign-in', body, { signal })
    );

  // TODO: add this endpoint ot the excludedEndpoints in the http client
  resendOtpCode = async (body: ResendOtpCodeBody) =>
    http.fetch((axios, signal) =>
      axios.post<object>('/auth/resend-code', body, { signal })
    );

  verifyOtpCode = async (body: VerifyOtpCodeBody) =>
    http.fetch((axios, signal) =>
      axios.post<Tokens>('/auth/verify-otp', body, { signal })
    );

  refreshTokens = async (body: RefreshTokensBody) =>
    http.fetch(
      (axios, signal) =>
        axios.post<Pick<Tokens, 'accessToken'>>('/auth/refresh', body, {
          signal,
        }),
      false
    );
}

export const authRepository = new AuthRepository();
