import { yupResolver } from '@hookform/resolvers/yup';
import { t } from 'i18next';
import React, { FC, ReactElement } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { CreateEditSessionBody } from 'common/models';
import {
  clearNullFieldsFromObject,
  transformEmptyObjectsToNull,
} from 'common/utils';
import { TextInput } from 'core/components/form';
import { CircularIndicator } from 'core/components/uikit';

import {
  BlacklistInput,
  MarketParameters,
  MarketParametersProps,
} from './components';
import { CreateEditSessionFormDefaultValues, transformOut } from './transforms';
import {
  createEditSessionSchema,
  CreateEditSessionFormData,
  CreateEditSessionErrors,
} from './validationSchema';

export type CreateEditSessionFormProps = {
  disabled?: MarketParametersProps['disabled'] & {
    submitButton?: boolean;
    sessionName?: boolean;
    blacklist?: boolean;
  };
  actionButtonText?: string;
  renderActionButtons?: (props: { isSubmitting: boolean }) => ReactElement;
  defaultValues?: CreateEditSessionFormDefaultValues;
  onSubmit: (values: CreateEditSessionBody) => Promise<void>;
};
const CreateEditSessionForm: FC<CreateEditSessionFormProps> = ({
  onSubmit,
  disabled,
  defaultValues,
  actionButtonText,
  renderActionButtons,
}) => {
  const methods = useForm<CreateEditSessionFormData>({
    resolver: yupResolver(createEditSessionSchema),
    defaultValues,
    mode: 'all',
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = methods;

  const rootError = (errors as CreateEditSessionErrors).main?.message;

  const onHandleSubmit = handleSubmit(async (values) => {
    const transformedValues = transformOut(values);
    const valuesWithoutEmptyObjects =
      transformEmptyObjectsToNull(transformedValues) ??
      ({} as CreateEditSessionBody);
    const cleanedValues = clearNullFieldsFromObject(valuesWithoutEmptyObjects);
    await onSubmit(cleanedValues);
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onHandleSubmit} className="flex flex-col gap-6">
        <TextInput
          control={control}
          name="sessionName"
          componentProps={{
            disabled: disabled?.sessionName,
            type: 'text',
            label: t('pages.createSession.sessionName.label'),
          }}
        />
        <MarketParameters control={control} disabled={disabled} />
        <hr className="border-t border-gray-300" />
        <BlacklistInput control={control} disabled={disabled?.blacklist} />
        <div className="flex flex-col gap-2">
          {rootError && (
            <p className="caption ml-1 mt-1 text-sm text-appRose">
              {rootError}
            </p>
          )}
          {renderActionButtons ? (
            renderActionButtons({ isSubmitting })
          ) : (
            <button type="submit" disabled={disabled?.submitButton}>
              {isSubmitting ? (
                <CircularIndicator />
              ) : (
                (actionButtonText ?? t('common.continue'))
              )}
            </button>
          )}
        </div>
      </form>
    </FormProvider>
  );
};

export default CreateEditSessionForm;
