import { useCallback } from 'react';

import { useFetch } from 'common/hooks';
import { ordersRepository } from 'common/repositories';

export type OrdersExportArgs = Parameters<
  typeof ordersRepository.exportOrders
>[0];

export const useExportOrders = () => {
  const { isLoading, fetch } = useFetch(ordersRepository.exportOrders, {
    instantFetch: false,
  });

  const exportOrders = useCallback(
    async (args: OrdersExportArgs) => {
      const response = await fetch(args);

      if (!response.data) {
        return;
      }

      const blob = new Blob([response.data], { type: 'text/csv' });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'orders.csv';

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    },
    [fetch]
  );

  return { exportOrders, isLoading };
};
