export const sanitizeNumberInput = (value: string) =>
  value.replace(/[^0-9.]/g, '');

export const formatNumber = (value: string) => {
  let sanitized = sanitizeNumberInput(value);

  if (sanitized.startsWith('.')) sanitized = sanitized.slice(1);

  const parts = sanitized.split('.');
  return parts.length > 2
    ? `${parts[0]}.${parts.slice(1).join('')}`
    : sanitized;
};

export const unformatNumber = (value: string) => value.replace(/[^0-9.]/g, '');

export const formatBigNumber = (value: string) => {
  const numberValue = formatNumber(value);
  return numberValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const unformatBigNumber = (value: string) =>
  unformatNumber(value).replace(/,/g, '');
