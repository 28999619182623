import axios, {
  AxiosInstance,
  AxiosError,
  InternalAxiosRequestConfig,
} from 'axios';
import { t } from 'i18next';
import { enqueueSnackbar } from 'notistack';

import { useAuthStore } from 'common/stores';
import useSettingsStore from 'common/stores/settingsStore';
import i18n from 'core/lang';

import { ApiCall, ApiResponse } from './types';

class Http {
  _axios: AxiosInstance;
  _controller = new AbortController();
  _isTokenRefreshing = false;
  _refreshSubscribers: ((token: string | null) => void)[] = [];

  get axios() {
    return this._axios;
  }

  constructor() {
    this._axios = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL,
      timeout: 10000,
    });

    this._axios.interceptors.response.use(
      (res) => res,
      async (error: AxiosError) => {
        const res = error.response;
        const status = res?.status;
        const requestUrl = error.config?.url;

        const excludedEndpoints = ['/refresh', '/sign-in', '/verify-otp'];
        const isExcludedEndpoint =
          requestUrl &&
          excludedEndpoints.some((endpoint) => requestUrl.includes(endpoint));

        if (status === 401 && !isExcludedEndpoint) {
          return this._handle401Error(error);
        }

        return Promise.reject(error);
      }
    );
  }

  private async _handle401Error(error: AxiosError) {
    const originalRequest = error.config as InternalAxiosRequestConfig & {
      _retry?: boolean;
    };

    if (!originalRequest || originalRequest._retry) {
      return Promise.reject(error);
    }

    originalRequest._retry = true;

    const retryRequestPromise = new Promise((resolve) => {
      this._refreshSubscribers.push((token) => {
        if (token) {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(this._axios(originalRequest));
        } else {
          resolve(Promise.reject(error));
        }
      });
    });

    if (!this._isTokenRefreshing) {
      this._isTokenRefreshing = true;

      try {
        const accessToken = await useAuthStore.getState().refresh();

        this._refreshSubscribers.forEach((callback) => callback(accessToken));
        this._refreshSubscribers = [];
      } catch (refreshError) {
        return Promise.reject(refreshError);
      } finally {
        this._isTokenRefreshing = false;
      }
    }

    return retryRequestPromise;
  }

  setAuthTokenToHeaders(token: string): void {
    this._axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  clearAuthTokenFromHeaders(): void {
    this._axios.defaults.headers.common['Authorization'] = '';
  }

  async fetch<ReturnType>(
    apiCall: ApiCall<ReturnType | null>,
    showError: boolean = true,
    throwError: boolean = false
  ): Promise<ApiResponse<ReturnType | null>> {
    try {
      const { data } = await apiCall(this._axios, this._controller.signal);

      return { data, error: null };
    } catch (error) {
      if (throwError) {
        throw error;
      }

      let message = i18n.t('errors.api.other');
      let err: AxiosError<ReturnType> = { message } as AxiosError<ReturnType>;

      if (error instanceof AxiosError) {
        const res = error.response;
        try {
          message =
            res?.data?.message ||
            (res?.data?.messages as string[])[0] ||
            error.message ||
            t('errors.api.other');
        } catch (error) {
          message = t('errors.api.other');
        }
        err = error;
      }

      const appSettings = useSettingsStore.getState().appSettings;
      const showSnackbarErrorMessages =
        appSettings?.showSnackbarErrorMessages ?? true;

      if (showError && showSnackbarErrorMessages) {
        enqueueSnackbar(message, {
          variant: 'error',
          hideIconVariant: true,
        });
      }

      return {
        data: null,
        error: err,
      };
    }
  }
}

const http = new Http();

export default http;
