import { useEffect } from 'react';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

import {
  Session,
  PaginatedResult,
  CreateEditSessionBody,
  EditSessionBody,
  StopSessionBody,
} from 'common/models';
import { sessionsRepository } from 'common/repositories';

type State = {
  isLoading: boolean;
  sessions: Session[] | null;
};

type Actions = {
  fetch: () => Promise<PaginatedResult<Session> | null>;
  setLoading: (value: boolean) => void;
  setSession: (session: Session) => void;
  setSessions: (sessions: Session[]) => void;
  deleteSession: (sessionId: string) => Promise<boolean>;
  stopSession: (body: StopSessionBody) => Promise<Session | null>;
  startSession: (sessionId: string) => Promise<Session | null>;
  editSession: (body: EditSessionBody) => Promise<Session | null>;
  createSession: (body: CreateEditSessionBody) => Promise<Session | null>;
};

export type SessionsStore = State & Actions;

const defaultState: State = {
  isLoading: false,
  sessions: null,
};

const useSessionsStore = create<SessionsStore>()(
  immer((set, get) => ({
    ...defaultState,

    setLoading: (value) =>
      set((state) => {
        state.isLoading = value;
      }),

    setSessions: (value) =>
      set((state) => {
        state.sessions = value;
      }),

    setSession: async (session) => {
      const { sessions } = get();

      if (!sessions) {
        return;
      }

      const sessionIndex = sessions.findIndex(
        (item) => item?.sessionId === session.sessionId
      );

      if (sessionIndex === -1) {
        return;
      }

      set((draft) => {
        if (draft.sessions) {
          draft.sessions[sessionIndex] = session;
        }
      });

      return true;
    },

    fetch: async () => {
      const { setLoading, setSessions } = get();

      setLoading(true);

      const res = await sessionsRepository.getSessions({
        page: 1,
        perPage: 100,
        orderDir: 'desc',
        orderBy: 'createdAt',
      });

      setLoading(false);

      const data = res.data;

      if (data === null) {
        return null;
      }

      setSessions(data?.items ?? []);

      return data;
    },

    createSession: async (body) => {
      const { sessions, setSessions } = get();

      const res = await sessionsRepository.createSession(body);

      const newSession = res.data;

      if (newSession === null) {
        return null;
      }

      const newSessions = [newSession, ...(sessions ?? [])];

      setSessions(newSessions);

      return newSession;
    },

    stopSession: async ({ sessionId, stopBehavior }) => {
      const { setSession } = get();
      const res = await sessionsRepository.stopSession({
        sessionId,
        stopBehavior,
      });

      if (res.data === null) {
        return null;
      }

      const getSessionRes = await sessionsRepository.getSessionById({
        sessionId,
      });
      const stoppedSession = getSessionRes.data;

      if (!stoppedSession) {
        return null;
      }

      setSession(stoppedSession);

      return stoppedSession;
    },

    editSession: async (body) => {
      const { sessions, setSessions } = get();

      const res = await sessionsRepository.editSession(body);

      const editedSession = res.data;

      if (editedSession === null) {
        return null;
      }

      const newSessions =
        sessions?.map((item) => {
          if (item.sessionId === editedSession.sessionId) {
            return editedSession;
          }

          return item;
        }) ?? [];

      setSessions(newSessions);

      return editedSession;
    },

    startSession: async (sessionId) => {
      const { setSession } = get();
      const res = await sessionsRepository.startSession({ sessionId });

      if (res.data === null) {
        return null;
      }

      const getSessionRes = await sessionsRepository.getSessionById({
        sessionId,
      });
      const startedSession = getSessionRes.data;

      if (!startedSession) {
        return null;
      }

      setSession(startedSession);

      return startedSession;
    },

    deleteSession: async (sessionId) => {
      const { setSessions, sessions } = get();
      const res = await sessionsRepository.deleteSession({ sessionId });

      if (res.data === null) {
        return false;
      }

      const newSessions =
        sessions?.filter((item) => item.sessionId !== sessionId) ?? [];

      setSessions(newSessions);

      return true;
    },
  }))
);

export const useFetchSessions = () => {
  const data = useSessionsStore((state) => state.sessions);
  const isLoading = useSessionsStore((state) => state.isLoading);

  useEffect(() => {
    useSessionsStore.getState().fetch();
  }, []);

  return { data, isLoading };
};

export default useSessionsStore;
