import { Field, Label } from '@headlessui/react';
import { clsx } from 'clsx';
import React, { ComponentProps } from 'react';
import EpSelect from 'react-select';

import { InputProps } from './types';

export type SelectProps = InputProps &
  Omit<ComponentProps<EpSelect>, 'isDisabled'> & {
    disabled?: boolean;
  };

const Select = ({
  classNames,
  className,
  label,
  error,
  disabled,
  ...selectProps
}: SelectProps) => {
  return (
    <Field
      className={clsx('flex w-full flex-col', className)}
      disabled={disabled}
    >
      {label &&
        (typeof label === 'string' ? (
          <Label className="caption mb-1 ml-1 text-xs">{label}</Label>
        ) : (
          label
        ))}
      <EpSelect
        placeholder=""
        classNames={{
          control: () =>
            `!border-[1px] !border-slate-400 !min-h-[42px] !rounded-md ${disabled ? '!bg-slate-200' : '!bg-slate-100'}`,
          multiValue: () => '!bg-white !rounded-md',
          multiValueLabel: () => '!bg-white !rounded-md',
          placeholder: () => '!text-sm',
          ...classNames,
        }}
        isDisabled={disabled}
        {...selectProps}
      />
      {error && (
        <p className="caption ml-1 mt-1 text-sm text-appRose">{error}</p>
      )}
    </Field>
  );
};

export default Select;
